import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faExternalLinkAlt, faFileAlt, faInfoCircle, faListAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const HTMLAnalysis = ({ htmlAnalysis, finalScore, cardBgColor }) => {
  if (!htmlAnalysis || typeof htmlAnalysis !== 'object' || !htmlAnalysis.analysis) {
    return (
      <Box bg={cardBgColor} shadow="md" rounded="lg" p={6}>
        <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
          <FontAwesomeIcon icon={faFileCode} className="mr-2 text-gray-900 dark:text-white" aria-hidden="true" /> HTML Analysis
        </Heading>
        <Alert
          status="info"
          variant="left-accent"
          borderRadius="md"
          className="bg-blue-100 dark:bg-blue-900/30"
        >
          <AlertIcon className="text-blue-500 dark:text-blue-300" />
          <Box>
            <AlertTitle fontSize="lg" mb={2} className="text-black">
              HTML Analysis Not Available
            </AlertTitle>
            <AlertDescription className="text-black">
              HTML analysis was not performed for this URL. This may be due to the site being unavailable or not containing analyzable HTML content.
            </AlertDescription>
          </Box>
        </Alert>
      </Box>
    );
  }

  // Function to clean the JSON string
  const cleanJsonString = (str) => {
    // Remove any potential Unicode BOM
    str = str.replace(/^\uFEFF/, '');
    // Replace any incorrectly escaped characters
    str = str.replace(/\\([^"\\\/bfnrtu])/g, '$1');
    return str;
  };

  let parsedAnalysis;
  try {
    // First, try parsing the entire htmlAnalysis as JSON
    parsedAnalysis = JSON.parse(cleanJsonString(JSON.stringify(htmlAnalysis)));
    
    // If successful, extract the inner JSON from the 'analysis' field
    if (parsedAnalysis.analysis) {
      const innerJsonMatch = parsedAnalysis.analysis.match(/```json\n([\s\S]*?)\n```/);
      if (innerJsonMatch) {
        parsedAnalysis = JSON.parse(cleanJsonString(innerJsonMatch[1]));
      } else {
        throw new Error("Inner JSON not found");
      }
    }
  } catch (error) {
    console.error("Error in first parsing attempt:", error);
    // If that fails, try extracting JSON from between triple backticks
    try {
      const jsonString = htmlAnalysis.analysis.match(/```json\n([\s\S]*?)\n```/)[1];
      parsedAnalysis = JSON.parse(cleanJsonString(jsonString));
    } catch (innerError) {
      console.error("Error in second parsing attempt:", innerError);
      return (
        <Box bg={cardBgColor} shadow="md" rounded="lg" p={6}>
          <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
            <FontAwesomeIcon icon={faFileCode} className="mr-2 text-gray-900 dark:text-white" aria-hidden="true" /> HTML Analysis
          </Heading>
          <Alert
            status="error"
            variant="left-accent"
            borderRadius="md"
            className="bg-red-100 dark:bg-red-900/30"
          >
            <AlertIcon as={FontAwesomeIcon} icon={faExclamationTriangle} className="text-red-500 dark:text-red-300" />
            <Box>
              <AlertTitle fontSize="lg" mb={2} className="text-red-800 dark:text-black">
                Error Parsing HTML Analysis
              </AlertTitle>
              <AlertDescription className="text-red-700 dark:text-black">
                There was an error processing the HTML analysis data. This could be due to an unexpected format or corrupted data. If this issue persists, please contact{' '}
                <a 
                  href="mailto:support@aiurl.io" 
                  className="text-blue-600 dark:text-blue-400 hover:underline"
                  aria-label="Email support at support@aiurl.io"
                >
                  support@aiurl.io
                </a>
                .
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
      );
    }
  }

  console.log("Parsed Analysis:", parsedAnalysis);

  const { analysis } = parsedAnalysis;

  return (
    <Box bg={cardBgColor} shadow="md" rounded="lg" p={6}>
      <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faFileCode} className="mr-2 text-gray-900 dark:text-white" aria-hidden="true" /> HTML Analysis
      </Heading>
      <VStack spacing={6} align="stretch">
        <Alert
          status="info"
          variant="left-accent"
          borderRadius="md"
          className="bg-blue-100 text-gray-800"
        >
          <Box>
            <AlertTitle fontSize="lg" mb={2} className="text-gray-800">
              HTML Analysis Summary
            </AlertTitle>
            <AlertDescription className="text-gray-800">
              {analysis.summary}
            </AlertDescription>
          </Box>
        </Alert>

        <Box>
          <Heading as="h3" size="md" mb={4} className="text-gray-900 dark:text-white">
            Analysis Details
          </Heading>
          <List spacing={4}>
            <ListItem display="flex">
              <ListIcon as={FontAwesomeIcon} icon={faExternalLinkAlt} color="blue.500" mt={1} className="text-blue-500" aria-hidden="true" flexShrink={0} />
              <Text className="text-gray-700 dark:text-gray-200">
                <strong className="text-gray-900 dark:text-white">Suspicious URLs:</strong>{' '}
                <span style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                  {analysis.suspicious_urls}
                </span>
              </Text>
            </ListItem>
            <ListItem display="flex">
              <ListIcon as={FontAwesomeIcon} icon={faFileAlt} color="purple.500" mt={1} className="text-purple-500" aria-hidden="true" />
              <Text className="text-gray-700 dark:text-gray-200"><strong className="text-gray-900 dark:text-white">Deobfuscated Content:</strong> {analysis.deobfuscated_context}</Text>
            </ListItem>
            <ListItem display="flex">
              <ListIcon as={FontAwesomeIcon} icon={faInfoCircle} color="blue.500" mt={1} className="text-blue-500" aria-hidden="true" />
              <Text className="text-gray-700 dark:text-gray-200"><strong className="text-gray-900 dark:text-white">Forms and Data Handling:</strong> {analysis.forms_and_data_handling}</Text>
            </ListItem>
            <ListItem display="flex">
              <ListIcon as={FontAwesomeIcon} icon={faListAlt} color="green.500" mt={1} className="text-green-500" aria-hidden="true" />
              <Text className="text-gray-700 dark:text-gray-200"><strong className="text-gray-900 dark:text-white">Additional Factors:</strong> {analysis.additional_factors}</Text>
            </ListItem>
          </List>
        </Box>
      </VStack>
    </Box>
  );
};

export default HTMLAnalysis;