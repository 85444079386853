import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeading } from '@fortawesome/free-solid-svg-icons';

const HeaderDetails = ({ headers, cardBgColor }) => {
  let parsedHeaders;
  try {
    parsedHeaders = typeof headers === 'string' ? JSON.parse(headers) : headers;
  } catch (error) {
    console.error("Error parsing headers:", error);
    return <Text className="text-red-500">Error parsing headers</Text>;
  }

  return (
    <Box bg={cardBgColor} shadow="md" rounded="lg" p={6}>
      <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faHeading} className="mr-2 text-gray-900 dark:text-white" aria-hidden="true" /> Header Details
      </Heading>
      <Box maxHeight="400px" overflowY="auto">
        <VStack align="stretch" spacing={2}>
          {Object.entries(parsedHeaders).map(([key, value]) => (
            <Box key={key}>
              <Text fontWeight="bold" className="text-gray-900 dark:text-white">{key}:</Text>
              <Text pl={4} whiteSpace="pre-wrap" wordBreak="break-word" className="text-gray-700 dark:text-gray-200">
                {typeof value === 'string' ? value : JSON.stringify(value, null, 2)}
              </Text>
            </Box>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default HeaderDetails;