import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';

const RelatedScanCard = ({ scan }) => {
  const getRiskColor = (score) => {
    if (score <= 33) return 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300';
    if (score <= 66) return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300';
    return 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300';
  };

  const getSimilarityColor = (similarity) => {
    const percentage = similarity * 100;
    if (percentage <= 25) return 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300';
    if (percentage <= 50) return 'bg-teal-100 text-teal-800 dark:bg-teal-900 dark:text-teal-300';
    if (percentage <= 75) return 'bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-300';
    return 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300';
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden shadow-sm">
      <div className="p-4">
        <div className="truncate font-bold text-sm text-gray-900 dark:text-white mb-1" title={scan.original_url}>
          {scan.original_url}
        </div>
        <div className="text-xs text-gray-700 dark:text-gray-400 mb-2">
          Domain: {scan.parsed_domain}
        </div>
        <div className="flex justify-between mb-2">
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${getRiskColor(scan.final_score)}`}>
            Risk: {scan.final_score}
          </span>
          {scan.similarity && (
            <span className={`px-2 py-1 rounded-full text-xs font-semibold ${getSimilarityColor(scan.similarity)}`}>
              Similarity: {Math.round(scan.similarity * 100)}%
            </span>
          )}
        </div>
        {scan.screenshot_url && (
          <img
            src={scan.screenshot_url}
            alt={`Screenshot of ${scan.original_url}`}
            className="w-full h-24 object-cover rounded-md mb-2"
          />
        )}
        <Link
          to={`/results/${scan.job_id}`}
          className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 dark:text-white bg-primary-600 hover:bg-primary-700 dark:bg-primary-500 dark:hover:bg-primary-600 rounded-md transition duration-150 ease-in-out"
        >
          View Details
          <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
        </Link>
      </div>
    </div>
  );
};

const RelatedScans = ({ relatedScans, cardBgColor }) => {
  return (
    <Box bg={cardBgColor} shadow="md" rounded="lg" p={6}>
      <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faLink} className="mr-2" aria-hidden="true" /> Related Scans
      </Heading>
      {relatedScans.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {relatedScans.map((scan) => (
            <RelatedScanCard key={scan.id} scan={scan} />
          ))}
        </div>
      ) : (
        <p className="text-gray-700 dark:text-gray-300">No related scans found.</p>
      )}
    </Box>
  );
};

export default RelatedScans;