import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../App.css';

const FirstTimeSetupModal = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchApiKey();
    }
  }, [isOpen]);

  const fetchApiKey = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();

      if (session) {
        const { data, error } = await supabase
          .from('users')
          .select('api_key')
          .eq('id', session.user.id)
          .single();

        if (error) throw error;

        setApiKey(data.api_key);
      }
    } catch (error) {
      console.error('Error fetching API key:', error);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const { data: { session } } = await supabase.auth.getSession();

      if (session) {
        const { error } = await supabase
          .from('users')
          .update({ name })
          .eq('id', session.user.id);

        if (error) throw error;

        setMessage('Your name has been saved successfully.');
      }
    } catch (error) {
      console.error('Error saving name:', error);
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative max-w-md w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 dark:text-gray-400">
          &times;
        </button>
        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-gray-200">
          Welcome! Complete Your Setup
        </h2>
        {message && (
          <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg" role="alert">
            {message}
          </div>
        )}
        <div className="flex flex-col space-y-4">
          <div className="flex items-center bg-white dark:bg-gray-700 rounded-lg shadow px-4 py-2">
            <input
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="flex-grow bg-transparent text-gray-800 dark:text-gray-200 outline-none"
            />
          </div>
          <div className="flex items-center bg-white dark:bg-gray-700 rounded-lg shadow px-4 py-2">
            <input
              type={showApiKey ? 'text' : 'password'}
              value={apiKey}
              readOnly
              className="flex-grow bg-transparent text-gray-800 dark:text-gray-200 outline-none"
            />
            <button onClick={() => setShowApiKey(!showApiKey)} className="ml-2">
              <FontAwesomeIcon icon={showApiKey ? faEyeSlash : faEye} className="text-gray-500 dark:text-gray-400" />
            </button>
          </div>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Make sure to write down your API key. You will not be able to see it again.
          </p>
          <button
            onClick={handleSave}
            disabled={loading}
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg shadow hover:bg-indigo-700 transition-colors duration-300"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstTimeSetupModal;