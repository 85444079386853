import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNetworkWired, faGlobe, faMapMarkerAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';

const IPInfoCard = ({ ipInfo }) => (
  <Box borderWidth="1px" borderColor="gray.700" borderRadius="lg" p={4} mb={4} width="100%">
    <HStack spacing={2} mb={2}>
      <FontAwesomeIcon icon={faGlobe} className="text-gray-900 dark:text-white" />
      <Text fontWeight="bold" className="text-gray-900 dark:text-white">{ipInfo.ip}</Text>
    </HStack>
    <VStack align="start" spacing={1}>
      <HStack>
        <FontAwesomeIcon icon={faMapMarkerAlt} className="text-gray-900 dark:text-white" />
        <Text className="text-gray-700 dark:text-gray-200">{ipInfo.city}, {ipInfo.country} ({ipInfo.countryCode})</Text>
      </HStack>
      <HStack>
        <FontAwesomeIcon icon={faBuilding} className="text-gray-900 dark:text-white" />
        <Text className="text-gray-700 dark:text-gray-200">{ipInfo.org}</Text>
      </HStack>
    </VStack>
  </Box>
);

const NetworkInformation = ({ scanData, cardBgColor }) => {
  const renderGeoData = () => {
    if (!scanData.geo_data) {
      return <Text className="text-gray-700 dark:text-gray-300">No geolocation data available.</Text>;
    }

    let geoDataArray;
    try {
      geoDataArray = typeof scanData.geo_data === 'string' ? JSON.parse(scanData.geo_data) : scanData.geo_data;
    } catch (error) {
      console.error("Error parsing geo_data:", error);
      return <Text className="text-gray-700 dark:text-gray-300">Error parsing geolocation data</Text>;
    }

    if (!Array.isArray(geoDataArray)) {
      geoDataArray = [geoDataArray];
    }

    return (
      <SimpleGrid columns={2} spacing={4} width="100%">
        {geoDataArray.map((ipInfo, index) => (
          <IPInfoCard key={index} ipInfo={ipInfo} />
        ))}
      </SimpleGrid>
    );
  };

  return (
    <Box bg={cardBgColor} shadow="md" rounded="lg" p={6} display="flex" flexDirection="column">
      <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faNetworkWired} className="mr-2 text-gray-900 dark:text-white" /> Network Information
      </Heading>
      {renderGeoData()}
    </Box>
  );
};

export default NetworkInformation;