import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../utils/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faLink, faExclamationCircle, faExternalLinkAlt, faCopy, faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Footer from './Footer';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Breadcrumbs from './Breadcrumbs';
import ScanHero from './results/ScanHero';
import SecurityInfo from './results/SecurityInfo';
import SuspiciousIndicators from './results/SuspiciousIndicators';
import NetworkInformation from './results/NetworkInformation';
import DomainInformation from './results/DomainInformation';
import AIAnalysis from './results/AIAnalysis';
import HeaderDetails from './results/HeaderDetails';
import RelatedScans from './results/RelatedScans';
import Screenshot from './results/Screenshot';
import HTMLAnalysis from './results/HTMLAnalysis';
import RawHTML from './results/RawHTML';
import { Spinner, Center, useToast, Text, Box, Alert, AlertIcon, AlertDescription, Button } from '@chakra-ui/react';
import LoginModal from './LoginModal';
import { Helmet } from 'react-helmet-async';
import NotFoundPage from './NotFoundPage';

// Register the HTML language
SyntaxHighlighter.registerLanguage('html', html);

const getRiskAssessment = (riskScore) => {
  if (riskScore === null || riskScore === undefined) {
    return {
      title: 'Unknown',
      color: 'text-blue-700 dark:text-blue-300',
      bgColor: 'bg-blue-50 dark:bg-blue-900/20',
      icon: faInfoCircle,
      scoreColor: 'text-blue-600 dark:text-blue-400'
    };
  } else if (riskScore > 80) {
    return {
      title: 'High Risk Detected',
      color: 'text-red-700 dark:text-red-300',
      bgColor: 'bg-red-50 dark:bg-red-900/20',
      icon: faExclamationTriangle,
      scoreColor: 'text-red-600 dark:text-red-400'
    };
  } else if (riskScore >= 40) {
    return {
      title: 'Moderate Risk Detected',
      color: 'text-yellow-700 dark:text-yellow-300',
      bgColor: 'bg-yellow-50 dark:bg-yellow-900/20',
      icon: faExclamationTriangle,
      scoreColor: 'text-yellow-600 dark:text-yellow-400'
    };
  } else {
    return {
      title: 'Low Risk Detected',
      color: 'text-green-700 dark:text-green-300',
      bgColor: 'bg-green-50 dark:bg-green-900/20',
      icon: faInfoCircle,
      scoreColor: 'text-green-600 dark:text-green-400'
    };
  }
};

const ResultsPage = () => {
  const { jobId } = useParams();
  const [results, setResults] = useState(null);
  const [childScans, setChildScans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { session } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [relatedScans, setRelatedScans] = useState([]);
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(true);
  const [completedSections, setCompletedSections] = useState({});
  const [aiAnalysisStatus, setAiAnalysisStatus] = useState('loading');
  const [htmlAnalysisStatus, setHtmlAnalysisStatus] = useState('loading');
  const [isSiteAlive, setIsSiteAlive] = useState(null);
  const [scanStatus, setScanStatus] = useState(null);
  const [initialDataReceived, setInitialDataReceived] = useState(false);
  const [activeTab, setActiveTab] = useState('original');
  const toast = useToast();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast({
          title: 'URL copied to clipboard',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        toast({
          title: 'Failed to copy URL',
          description: err.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  }, [toast]);

  const fetchRelatedScans = useCallback(async (originalUrl, parsedDomain) => {
    try {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      const currentUrl = new URL(originalUrl);
      const currentPath = currentUrl.pathname + currentUrl.search;

      const { data, error } = await supabase
        .from('ripper_details')
        .select('id, job_id, original_url, parsed_domain, final_score, timestamp')
        .gt('timestamp', thirtyDaysAgo.toISOString())
        .not('job_id', 'eq', jobId)
        .order('timestamp', { ascending: false })
        .limit(20);

      if (error) throw error;

      const filteredData = data
        .map(scan => {
          const scanUrl = new URL(scan.original_url);
          const scanPath = scanUrl.pathname + scanUrl.search;
          const domainSimilarity = calculateSimilarity(parsedDomain, scan.parsed_domain);
          const pathSimilarity = calculateSimilarity(currentPath, scanPath);
          const overallSimilarity = (domainSimilarity + pathSimilarity) / 2;
          return { ...scan, similarity: overallSimilarity };
        })
        .filter(scan => scan.parsed_domain === parsedDomain || scan.similarity > 0.7)
        .sort((a, b) => b.similarity - a.similarity)
        .slice(0, 5);

      setRelatedScans(filteredData);
    } catch (error) {
      console.error('Error fetching related scans:', error);
    }
  }, [jobId]);

  const calculateSimilarity = (str1, str2) => {
    if (!str1 || !str2) return 0;
    const longer = str1.length > str2.length ? str1 : str2;
    const shorter = str1.length > str2.length ? str2 : str1;
    const longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  };

  const editDistance = (str1, str2) => {
    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();
    const costs = new Array();
    for (let i = 0; i <= str1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= str2.length; j++) {
        if (i === 0) {
          costs[j] = j;
        } else if (j > 0) {
          let newValue = costs[j - 1];
          if (str1.charAt(i - 1) !== str2.charAt(j - 1)) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          }
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
      if (i > 0) {
        costs[str2.length] = lastValue;
      }
    }
    return costs[str2.length];
  };

  const fetchResults = async () => {
    try {
      while (true) {
        const { data, error } = await supabase
          .from('ripper_details')
          .select('*')
          .eq('job_id', jobId)
          .single();

        if (error) {
          // Handle Supabase error
          console.error('Error fetching results:', error);
          if (error.message === 'JSON object requested, multiple (or no) rows returned') {
            // Redirect to NotFoundPage if jobId doesn't exist
            navigate('/not-found');
          } else {
            setError('Failed to fetch results. Please try again.');
            setInitialDataReceived(true);
          }
          setLoading(false);
          return;
        }

        setResults(data);
        setIsSiteAlive(data.alive === 'alive');
        setScanStatus(data.status);
        setInitialDataReceived(true);

        setCompletedSections(prevSections => ({
          ...prevSections,
          scanHero: true,
          securityInfo: !!(data.web_server || data.safebrowsing || data.cert_details || data.jarm_fingerprint),
          suspiciousIndicators: !!data.suspicious_indicators,
          networkInformation: !!data.ip_addresses,
          domainInformation: !!(data.whois || data.mx_records),
          headerDetails: !!data.headers,
          screenshot: !!data.screenshot_url,
          rawHtml: !!data.raw_html
        }));

        // Check if processing_status is 'ERROR'
        if (data.processing_status === 'ERROR') {
          setIsProcessing(false);
          break;
        }

        setIsProcessing(data.processing_status !== 'Completed');

        if (data.processing_status === 'Completed') {
          setAiAnalysisStatus(data.ai_analysis ? 'completed' : 'not_available');
          setHtmlAnalysisStatus(data.html_analysis && typeof data.html_analysis === 'object' && data.html_analysis.analysis ? 'completed' : 'not_available');

          fetchRelatedScans(data.original_url, data.parsed_domain);

          const { data: childData, error: childError } = await supabase
            .from('ripper_details')
            .select('*')
            .eq('parent_id', data.id);

          if (childError) throw childError;

          setChildScans(childData);
          break;
        }

        await new Promise(resolve => setTimeout(resolve, 2000));
      }
    } catch (error) {
      console.error('Error fetching results:', error);
      setError('Failed to fetch results. Please try again.');
      setInitialDataReceived(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResults();
  }, [jobId, session, navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (session && session.user) {
        try {
          const { data, error } = await supabase
            .from('users')
            .select('name, avatar_url')
            .eq('id', session.user.id)
            .single();

          if (error) throw error;

          setUser({
            name: data.name || 'Anonymous User',
            avatar_url: data.avatar_url
          });
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUser({
            name: 'Anonymous User',
            avatar_url: null
          });
        }
      }
    };

    fetchUserData();
  }, [session]);

  const getRiskColor = (score) => {
    if (score < 30) return 'bg-green-100 text-green-800';
    if (score < 70) return 'bg-yellow-100 text-yellow-800';
    return 'bg-red-100 text-red-800';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const getAlertStatus = (classification) => {
    const lowerCaseClassification = classification.toLowerCase();
    if (lowerCaseClassification.includes('not malicious')) {
      return 'success';
    } else if (lowerCaseClassification.includes('malicious')) {
      return 'error';
    }
    return 'warning';
  };

  const renderScanContent = (scanData) => {
    if (scanData.processing_status === 'ERROR') {
      return (
        <ScanHero
          scanData={scanData}
          copyToClipboard={copyToClipboard}
          user={user}
        />
      );
    }

    // New condition to check if status is 'Legitimate Domain'
    if (scanData.status === 'Legitimate Domain') {
      return (
        <ScanHero
          scanData={scanData}
          copyToClipboard={copyToClipboard}
          user={user}
        />
      );
    }

    const renderNotAliveMessage = (title, description) => (
      <div className="bg-yellow-100 dark:bg-yellow-800 border-l-4 border-yellow-500 text-yellow-700 dark:text-yellow-200 p-4 mb-6 rounded-md" role="alert">
        <p className="font-bold">{title}</p>
        <p>{description}</p>
      </div>
    );

    const renderSkeletonLoader = (title) => (
      <div className="bg-white dark:bg-gray-800 p-5 rounded-md shadow-md mb-6" aria-busy="true" aria-label={`Loading ${title}`}>
        <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">{title}</h2>
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-3/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded"></div>
              <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-5/6"></div>
            </div>
          </div>
        </div>
        <p className="mt-4 font-bold text-center text-gray-700 dark:text-gray-300">Analyzing...</p>
      </div>
    );

    return (
      <>
        {isProcessing && (
          <Box mt={4} mb={8} textAlign="center" aria-live="polite">
            <Center>
              <Spinner size="xl" color="blue.500" aria-label="Processing scan" />
            </Center>
            <Text mt={4} color="gray.500">
              Analyzing the URL, please wait...
            </Text>
          </Box>
        )}

        {completedSections.scanHero ? (
          <ScanHero 
            scanData={scanData} 
            copyToClipboard={copyToClipboard}
            user={user}
          />
        ) : (
          <div className="h-48 bg-gray-300 dark:bg-gray-700 animate-pulse mb-6 rounded-md"></div>
        )}

        {isSiteAlive === false && renderNotAliveMessage(
          "Site Not Alive",
          "The site was not alive at the time of the scan. Some information may be limited or unavailable."
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {completedSections.securityInfo ? (
            <SecurityInfo scanData={scanData} />
          ) : isSiteAlive === false ? (
            renderNotAliveMessage("No Security Info", "Security information is not available for sites that were not alive during the scan.")
          ) : (
            <div className="h-48 bg-gray-300 dark:bg-gray-700 animate-pulse rounded-md"></div>
          )}

          {completedSections.suspiciousIndicators ? (
            <SuspiciousIndicators scanData={scanData} />
          ) : isSiteAlive === false ? (
            renderNotAliveMessage("No Suspicious Indicators", "Suspicious indicator information is not available for sites that were not alive during the scan.")
          ) : (
            <div className="h-48 bg-gray-300 dark:bg-gray-700 animate-pulse rounded-md"></div>
          )}

          {completedSections.networkInformation ? (
            <NetworkInformation scanData={scanData} />
          ) : isSiteAlive === false ? (
            renderNotAliveMessage("Limited Network Information", "Detailed network information may be limited for sites that were not alive during the scan.")
          ) : (
            <div className="h-48 bg-gray-300 dark:bg-gray-700 animate-pulse rounded-md"></div>
          )}

          {completedSections.domainInformation ? (
            <DomainInformation scanData={scanData} />
          ) : isSiteAlive === false ? (
            renderNotAliveMessage("Limited Domain Information", "Some domain information may still be available even though the site was not alive during the scan.")
          ) : (
            <div className="h-48 bg-gray-300 dark:bg-gray-700 animate-pulse rounded-md"></div>
          )}
        </div>

        <div className="mb-6">
          {!initialDataReceived ? (
            renderSkeletonLoader("AI Analysis")
          ) : isSiteAlive === false ? (
            renderNotAliveMessage(
              "AI Analysis Unavailable",
              "AI analysis could not be performed as the site was not responsive during the scan."
            )
          ) : isProcessing || aiAnalysisStatus === 'loading' ? (
            renderSkeletonLoader("AI Analysis")
          ) : (
            <AIAnalysis scanData={scanData} />
          )}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          {completedSections.headerDetails ? (
            <HeaderDetails headers={scanData.headers} />
          ) : isSiteAlive === false ? (
            renderNotAliveMessage("No Header Details", "Header details are not available for sites that were not alive during the scan.")
          ) : (
            <div className="h-48 bg-gray-300 dark:bg-gray-700 animate-pulse rounded-md"></div>
          )}

          <RelatedScans relatedScans={relatedScans} getRiskColor={getRiskColor} />
        </div>

        {completedSections.screenshot ? (
          <div className="mb-6">
            <Screenshot scanData={scanData} />
          </div>
        ) : isSiteAlive === false ? (
          renderNotAliveMessage("No Screenshot", "Screenshot is not available for sites that were not alive during the scan.")
        ) : (
          <div className="h-96 bg-gray-300 dark:bg-gray-700 animate-pulse mb-6 rounded-md"></div>
        )}

        <div className="mb-6">
          {!initialDataReceived ? (
            renderSkeletonLoader("HTML Analysis")
          ) : isSiteAlive === false ? (
            renderNotAliveMessage(
              "HTML Analysis Unavailable",
              "HTML analysis could not be performed as the site was not responsive during the scan."
            )
          ) : isProcessing || htmlAnalysisStatus === 'loading' ? (
            renderSkeletonLoader("HTML Analysis")
          ) : (
            <HTMLAnalysis htmlAnalysis={scanData.html_analysis} finalScore={scanData.final_score} />
          )}
        </div>

        {completedSections.rawHtml ? (
          <div className="mb-6">
            <RawHTML rawHtml={scanData.raw_html} />
          </div>
        ) : isSiteAlive === false ? (
          renderNotAliveMessage("No Raw HTML", "Raw HTML is not available for sites that were not alive during the scan.")
        ) : (
          <div className="h-48 bg-gray-300 dark:bg-gray-700 animate-pulse mb-6 rounded-md"></div>
        )}
      </>
    );
  };

  if (loading || isProcessing) return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Header />
      <main className="container mx-auto py-10 px-4">
        <Breadcrumbs items={[
          { href: '/', label: 'Home' },
          { href: '/recent-scans', label: 'Recent Scans' },
          { href: '#', label: `${jobId}` },
        ]} />
        {renderScanContent(results || {})}
      </main>
      <Footer />
    </div>
  );
  if (error) return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Header />
      <div className="container mx-auto py-10 px-4">
        <div className="max-w-lg mx-auto">
          {error === 'You must be logged in to view private scan results.' ? (
            <Alert status="error" variant="solid" rounded="md">
              <AlertIcon />
              <AlertDescription mr={4}>{error}</AlertDescription>
              <Button onClick={() => setIsLoginModalOpen(true)} colorScheme="blue">
                Log in
              </Button>
            </Alert>
          ) : (
            <div className="text-red-500 dark:text-red-400 text-center">{error}</div>
          )}
        </div>
      </div>
      <Footer />
      <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />
    </div>
  );
  if (!results) return <NotFoundPage />;

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <title>AI URL Scanner | {results?.original_url || 'Scan Results'}</title>
        <meta
          name="description"
          content={
            results
              ? `Scan results for ${results.original_url}. Risk assessment: ${getRiskAssessment(
                  results.final_score
                ).title}`
              : 'Loading scan results...'
          }
        />
        <meta name="keywords" content="URL scanner, scan results, website security, threat detection" />
      </Helmet>

      <Header />
      <main className="container mx-auto py-10 px-4">
        <Breadcrumbs items={[
          { href: '/', label: 'Home' },
          { href: '/recent-scans', label: 'Recent Scans' },
          { href: '#', label: `${jobId}` },
        ]} />

        <div className="mb-6">
          <div className="border-b border-gray-200 dark:border-gray-700">
            <nav className="-mb-px flex" aria-label="Scan results navigation">
              <button
                className={`mr-1 py-2 px-4 text-sm font-medium ${
                  activeTab === 'original'
                    ? 'border-b-2 border-blue-500 text-blue-600 dark:text-blue-400'
                    : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
                }`}
                onClick={() => setActiveTab('original')}
                aria-current={activeTab === 'original' ? 'page' : undefined}
              >
                <FontAwesomeIcon icon={faGlobe} className="mr-2" aria-hidden="true" />
                Original URL
              </button>
              {childScans.map((child, index) => (
                <button
                  key={child.id}
                  className={`mr-1 py-2 px-4 text-sm font-medium ${
                    activeTab === `child-${index}`
                      ? 'border-b-2 border-blue-500 text-blue-600 dark:text-blue-400'
                      : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300'
                  }`}
                  onClick={() => setActiveTab(`child-${index}`)}
                  aria-current={activeTab === `child-${index}` ? 'page' : undefined}
                >
                  <FontAwesomeIcon icon={faLink} className="mr-2" aria-hidden="true" />
                  Discovered URL {index + 1}
                  {index === 0 && (
                    <span className="ml-2 animate-pulse" aria-label="New discovery">
                      <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500" aria-hidden="true" />
                    </span>
                  )}
                </button>
              ))}
            </nav>
          </div>
        </div>

        {activeTab === 'original' && renderScanContent(results)}
        {childScans.map((child, index) => (
          activeTab === `child-${index}` && renderScanContent(child)
        ))}
      </main>
      <Footer />
      <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />
    </div>
  );
};

export default ResultsPage;