import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { supabase } from '../utils/supabaseClient';
import '../App.css';

const LoginModal = ({ isOpen, onClose, onSwitchToSignup }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  const handleGoogleLogin = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.origin // Ensure this redirects back to your app
        }
      });
      if (error) throw error;
      // The user will be redirected to Google for authentication
      // You don't need to set a success message here as the page will reload
    } catch (error) {
      console.error('Error during Google login:', error);
      setMessage(error.error_description || error.message);
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  const handleMagicLinkLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      console.log('Sending magic link to:', email);
      const { error } = await supabase.auth.signInWithOtp({ email });
      if (error) throw error;

      setMessage('Check your email for the magic link!');
      setMessageType('success');
    } catch (error) {
      console.error('Error during magic link login:', error);
      setMessage(error.error_description || error.message);
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative max-w-md w-full bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 dark:text-gray-400">
          &times;
        </button>
        <div className="flex justify-center mb-8">
          <img className="h-8 w-auto hidden dark:block" src="/logo-dark.svg" alt="Your Logo" />
          <img className="h-8 w-auto dark:hidden" src="/logo-light.svg" alt="Your Logo" />
        </div>
        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 dark:text-gray-200">
          Log In
        </h2>
        {message && (
          <div className={`p-4 mb-4 text-sm ${messageType === 'success' ? 'text-green-700 bg-green-100' : 'text-red-700 bg-red-100'} rounded-lg`} role="alert">
            {message}
          </div>
        )}
        <div className="flex flex-col space-y-4">
          <button
            onClick={handleGoogleLogin}
            disabled={loading}
            className="gsi-material-button"
          >
            <div className="gsi-material-button-state"></div>
            <div className="gsi-material-button-content-wrapper">
              <div className="gsi-material-button-icon">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: 'block' }}>
                  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span className="gsi-material-button-contents">Continue with Google</span>
              <span style={{ display: 'none' }}>Continue with Google</span>
            </div>
          </button>
          <div className="flex items-center justify-between">
            <hr className="flex-grow border-gray-300 dark:border-gray-700" />
            <span className="mx-4 text-gray-500 dark:text-gray-400">or</span>
            <hr className="flex-grow border-gray-300 dark:border-gray-700" />
          </div>
          <div className="flex items-center bg-white dark:bg-gray-700 rounded-lg shadow px-4 py-2">
            <FontAwesomeIcon icon={faEnvelope} className="text-gray-500 dark:text-gray-400 mr-2" />
            <input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="flex-grow bg-transparent text-gray-800 dark:text-gray-200 outline-none"
            />
          </div>
          <button
            onClick={handleMagicLinkLogin}
            disabled={loading}
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg shadow hover:bg-indigo-700 transition-colors duration-300"
          >
            Log in with Magic Link
          </button>
        </div>

        {/* Add a link or button to switch to the SignupModal */}
        <p className="mt-4 text-center text-gray-600 dark:text-gray-400">
          Don't have an account?{' '}
          <button
            onClick={onSwitchToSignup}
            className="text-indigo-600 dark:text-indigo-400 hover:underline"
          >
            Sign up
          </button>
        </p>
      </div>
    </div>
  );
};

export default LoginModal;