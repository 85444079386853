import React, { useState, useEffect } from 'react';
import { VStack, FormControl, FormLabel, Input, Button, useToast, Heading, Text } from '@chakra-ui/react';
import { supabase } from '../utils/supabaseClient';
import DOMPurify from 'dompurify';

const UserDetailsSection = ({ userData, setUserData }) => {
  const [name, setName] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const toast = useToast();

  useEffect(() => {
    if (userData) {
      setName(userData.name || '');
      setAvatarUrl(userData.avatar_url || '');
    }
  }, [userData]);

  const sanitizeInput = (input) => {
    const sanitized = DOMPurify.sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
    const doc = new DOMParser().parseFromString(sanitized, 'text/html');
    return doc.body.textContent || "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sanitizedName = sanitizeInput(name);
    const sanitizedAvatarUrl = sanitizeInput(avatarUrl);

    if (sanitizedName !== name || sanitizedAvatarUrl !== avatarUrl) {
      toast({
        title: 'Input sanitized',
        description: 'Some potentially unsafe content was removed from your input.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }

    const { data, error } = await supabase
      .from('users')
      .update({ name: sanitizedName, avatar_url: sanitizedAvatarUrl })
      .eq('id', userData.id);

    if (error) {
      toast({
        title: 'Error updating user details',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      setUserData({ ...userData, name: sanitizedName, avatar_url: sanitizedAvatarUrl });
      setName(sanitizedName);
      setAvatarUrl(sanitizedAvatarUrl);
      toast({
        title: 'User details updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAvatarUrlChange = (e) => {
    setAvatarUrl(e.target.value);
  };

  return (
    <VStack spacing={6} align="stretch">
      <Heading as="h2" size="lg" className="text-gray-900 dark:text-white">User Details</Heading>
      <Text className="text-gray-600 dark:text-gray-400">Update your personal information here.</Text>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl>
            <FormLabel className="text-gray-700 dark:text-gray-300">Name</FormLabel>
            <Input value={name} onChange={handleNameChange} bg="white" className="bg-white dark:bg-gray-700 text-black" />
          </FormControl>
          <FormControl>
            <FormLabel className="text-gray-700 dark:text-gray-300">Avatar URL</FormLabel>
            <Input value={avatarUrl} onChange={handleAvatarUrlChange} bg="white" className="bg-white dark:bg-gray-700 text-black" />
          </FormControl>
          <Button type="submit" colorScheme="blue" className="text-white">
            Save Changes
          </Button>
        </VStack>
      </form>
    </VStack>
  );
};

export default UserDetailsSection;