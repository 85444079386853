import React, { useState } from 'react';
import {
  Box,
  Heading,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode } from '@fortawesome/free-solid-svg-icons';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import html from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// Register the HTML language
SyntaxHighlighter.registerLanguage('html', html);

const RawHTML = ({ rawHtml, cardBgColor }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box bg={cardBgColor} shadow="md" rounded="lg" p={6}>
      <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faFileCode} className="mr-2 text-gray-900 dark:text-white" /> Raw HTML
      </Heading>
      <Box 
        bg="gray.900" 
        borderRadius="md"
        maxHeight={isExpanded ? "none" : "300px"}
        overflow="hidden"
        position="relative"
      >
        <SyntaxHighlighter 
          language="html"
          style={atomOneDark}
          customStyle={{
            margin: 0,
            padding: '1em',
            backgroundColor: 'transparent',
          }}
        >
          {rawHtml}
        </SyntaxHighlighter>
        {!isExpanded && (
          <Box 
            position="absolute" 
            bottom="0" 
            left="0" 
            right="0" 
            height="100px" 
            bgGradient="linear(to-t, gray.900, transparent)"
          />
        )}
      </Box>
      <Button 
        onClick={() => setIsExpanded(!isExpanded)} 
        mt={4} 
        colorScheme="blue"
      >
        {isExpanded ? "Show Less" : "Show More"}
      </Button>
    </Box>
  );
};

export default RawHTML;