import React, { useState, useEffect, useContext } from 'react';
import { Box, Container, Flex, useColorModeValue, Select } from '@chakra-ui/react';
import { AuthContext } from '../utils/AuthContext';
import { supabase } from '../utils/supabaseClient';
import Header from './Header';
import Footer from './Footer';
import SettingsNav from './SettingsNav';
import UserDetailsSection from './UserDetailsSection';
import APIKeySection from './APIKeySection';
import WebhookSection from './WebhookSection';
import LoginModal from './LoginModal';
import { Helmet } from 'react-helmet-async'; // Import Helmet from react-helmet-async

const SettingsPage = () => {
  const [activePage, setActivePage] = useState('details');
  const [userData, setUserData] = useState(null);
  const { session } = useContext(AuthContext);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBgColor = useColorModeValue('white', 'gray.800');

  useEffect(() => {
    if (!session) {
      setIsLoginModalOpen(true); // Show login modal if not authenticated
    } else {
      setIsLoginModalOpen(false); // Hide login modal if authenticated
    }
  }, [session]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (session) {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('id', session.user.id)
          .single();

        if (error) {
          console.error('Error fetching user data:', error);
        } else {
          setUserData(data);
        }
      }
    };

    fetchUserData();
  }, [session]);

  const renderActivePage = () => {
    switch (activePage) {
      case 'details':
        return <UserDetailsSection userData={userData} setUserData={setUserData} />;
      case 'api':
        return <APIKeySection userData={userData} />;
      case 'webhook':
        return <WebhookSection userData={userData} />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col">
      <Helmet>
        <title>AI URL Scanner | Settings</title>
        <meta name="description" content="Manage your account settings, API keys, and webhooks for the AI URL Scanner." />
        <meta name="keywords" content="URL scanner, settings, account settings, API keys, webhooks" />
      </Helmet>

      <Header />
      <main className="container mx-auto py-10 px-4 sm:px-6 lg:px-8 flex-grow">
        <h1 className="sr-only">Settings Page</h1>
        <Flex direction={{ base: 'column', md: 'row' }} gap={8}>
          {/* Mobile dropdown */}
          <Box display={{ base: 'block', md: 'none' }} mb={4}>
            <Select
              value={activePage}
              onChange={(e) => setActivePage(e.target.value)}
              bg={cardBgColor}
              className="bg-white dark:bg-gray-800"
              aria-label="Select settings page"
            >
              <option value="details">User Details</option>
              <option value="api">API Key</option>
              <option value="webhook">Webhooks</option>
            </Select>
          </Box>

          {/* Desktop sidebar */}
          <Box width="200px" display={{ base: 'none', md: 'block' }}>
            <nav aria-label="Settings navigation">
              <SettingsNav activePage={activePage} setActivePage={setActivePage} />
            </nav>
          </Box>

          {/* Main content */}
          <Box flex={1} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
            {renderActivePage()}
          </Box>
        </Flex>
      </main>
      <Footer />
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onSwitchToSignup={() => {
          setIsLoginModalOpen(false);
          // Add logic to switch to signup modal if needed
        }}
      />
    </div>
  );
};

export default SettingsPage;