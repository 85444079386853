import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../utils/AuthContext';
import LoginModal from './LoginModal';

const ProtectedRoute = ({ children }) => {
  const { session } = useContext(AuthContext);
  const location = useLocation();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    if (!session) {
      setIsLoginModalOpen(true);
    }
  }, [session]);

  if (!session) {
    return (
      <>
        <Navigate to="/" state={{ from: location }} replace />
        <LoginModal
          isOpen={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
          onSwitchToSignup={() => {
            setIsLoginModalOpen(false);
            // Add logic to switch to signup modal if needed
          }}
        />
      </>
    );
  }

  return children;
};

export default ProtectedRoute;