const API_ENDPOINT = 'https://api.aiurl.io/analyze'; // This will be replaced with the actual server endpoint

export const analyzeUrl = async (url, userId, isPrivate) => {
  try {
    const response = await fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url, userId, isPrivate }), // Include userId and isPrivate in the request body
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.jobId;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};