import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  List,
  ListItem,
  ListIcon,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faServer, faEye, faFileAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const AIAnalysis = ({ scanData, cardBgColor }) => {
  if (!scanData.ai_analysis || typeof scanData.ai_analysis !== 'object' || !scanData.ai_analysis.analysis) {
    return (
      <Box bg={cardBgColor} shadow="md" rounded="lg" p={6}>
        <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-gray-900 dark:text-white" /> AI Analysis
        </Heading>
        <Alert
          status="info"
          variant="left-accent"
          borderRadius="md"
          className="bg-blue-50 dark:bg-blue-900 dark:bg-opacity-20"
        >
          <AlertIcon className="text-blue-500 dark:text-blue-300" />
          <Box>
            <AlertTitle fontSize="lg" mb={2} className="text-black">
              AI Analysis Not Available
            </AlertTitle>
            <AlertDescription className="text-black">
              AI analysis was not performed for this URL. This may be due to the site being unavailable, or our system determining that AI analysis was not necessary for this particular URL.
            </AlertDescription>
          </Box>
        </Alert>
      </Box>
    );
  }

  let analysis, classification;
  try {
    const parsedAnalysis = JSON.parse(scanData.ai_analysis.analysis.replace(/```json|```/g, ''));
    analysis = parsedAnalysis.analysis;
    classification = parsedAnalysis.classification;
  } catch (error) {
    console.error("Error parsing ai_analysis:", error);
    return (
      <Box bg={cardBgColor} shadow="md" rounded="lg" p={6}>
        <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-gray-900 dark:text-white" /> AI Analysis
        </Heading>
        <Alert
          status="error"
          variant="left-accent"
          borderRadius="md"
          className="bg-red-50 dark:bg-red-900 dark:bg-opacity-20"
        >
          <AlertIcon className="text-red-500 dark:text-red-300" />
          <Box>
            <AlertTitle fontSize="lg" mb={2} className="text-red-500 dark:text-red-300">
              Error in AI Analysis
            </AlertTitle>
            <AlertDescription className="text-gray-700 dark:text-gray-200">
              There was an error parsing the AI analysis results. Please try again later or contact support if the issue persists.
            </AlertDescription>
          </Box>
        </Alert>
      </Box>
    );
  }

  const getAlertStatus = (classification) => {
    const lowerCaseClassification = classification.toLowerCase();
    if (lowerCaseClassification.includes('not malicious')) {
      return 'success';
    } else if (lowerCaseClassification.includes('possibly malicious')) {
      return 'warning';
    } else if (lowerCaseClassification.includes('malicious')) {
      return 'error';
    }
    return 'warning';
  };

  return (
    <Box bg={cardBgColor} shadow="md" rounded="lg" p={6} width="100%">
      <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-gray-900 dark:text-white" aria-hidden="true" /> AI Analysis
      </Heading>
      <VStack spacing={6} align="stretch">
        <Alert
          status={getAlertStatus(classification)}
          variant="left-accent"
          borderRadius="md"
          className={`bg-${getAlertStatus(classification)}-100 dark:bg-${getAlertStatus(classification)}-900 text-${getAlertStatus(classification)}-800 dark:text-${getAlertStatus(classification)}-100`}
        >
          <Box>
            <AlertTitle fontSize="lg" mb={2} className={`text-${getAlertStatus(classification)}-800 dark:text-${getAlertStatus(classification)}-100`}>
              Classification: {classification}
            </AlertTitle>
            <AlertDescription className={`text-${getAlertStatus(classification)}-800 dark:text-${getAlertStatus(classification)}-100`}>
              {analysis.summary}
            </AlertDescription>
          </Box>
        </Alert>

        <Box>
          <Heading as="h3" size="md" mb={4} className="text-gray-900 dark:text-white">
            Analysis Details
          </Heading>
          <List spacing={4}>
            {[
              { icon: faLink, color: "blue.500", title: "URL Structure", content: analysis.url_structure },
              { icon: faServer, color: "orange.500", title: "Domain and Hosting", content: analysis.domain_and_hosting },
              { icon: faEye, color: "green.500", title: "Content and Visuals", content: analysis.content_and_visuals },
              { icon: faFileAlt, color: "purple.500", title: "Forms and Data Handling", content: analysis.forms_and_data_handling },
              { icon: faInfoCircle, color: "teal.500", title: "Additional Factors", content: analysis.additional_factors }
            ].map((item, index) => (
              <ListItem key={index} display="flex" alignItems="flex-start">
                <ListIcon 
                  as={FontAwesomeIcon} 
                  icon={item.icon} 
                  color={item.color} 
                  mt={1} 
                  mr={2}
                  flexShrink={0}
                  className={`text-${item.color}`}
                  aria-hidden="true"
                />
                <Box flexGrow={1}>
                  <Text className="text-gray-700 dark:text-gray-200">
                    <strong className="text-gray-900 dark:text-white">{item.title}:</strong>{' '}
                    <Box as="span" wordBreak="break-word" overflowWrap="break-word">
                      {item.content}
                    </Box>
                  </Text>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      </VStack>
    </Box>
  );
};

export default AIAnalysis;