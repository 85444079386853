import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Image,
  Badge,
  Center,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faTimes } from '@fortawesome/free-solid-svg-icons';

const Screenshot = ({ scanData, cardBgColor }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <Box bg={cardBgColor} shadow="md" rounded="lg" p={6}>
      <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faCamera} className="mr-2 text-gray-900 dark:text-white" aria-hidden="true" /> Screenshot
      </Heading>
      <Center>
        <VStack spacing={4} align="center" w="full">
          {scanData.screenshot_url ? (
            <Box
              maxW="800px"
              w="full"
              borderWidth={1}
              borderColor="gray.200"
              borderRadius="md"
              overflow="hidden"
              position="relative"
              cursor="pointer"
              onClick={openModal}
              role="button"
              aria-label="Open full-size screenshot"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  openModal();
                }
              }}
            >
              <Image
                src={scanData.screenshot_url}
                alt="Website Screenshot"
                objectFit="contain"
                w="full"
                fallback={<Skeleton h="400px" w="full" />}
              />
              <Badge
                position="absolute"
                top={2}
                right={2}
                colorScheme="blue"
                fontSize="sm"
                className="text-white"
              >
                Screenshot
              </Badge>
            </Box>
          ) : (
            <Text className="text-gray-700 dark:text-gray-300">No screenshot available.</Text>
          )}
          <Text fontSize="sm" className="text-gray-700 dark:text-gray-200">
            Screenshot taken on: {new Date(scanData.timestamp).toLocaleString()}
          </Text>
        </VStack>
      </Center>

      <Modal isOpen={isOpen} onClose={closeModal} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton>
            <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-700" aria-hidden="true" />
          </ModalCloseButton>
          <ModalBody p={0}>
            <Image
              src={scanData.screenshot_url}
              alt="Full-size Website Screenshot"
              objectFit="contain"
              w="full"
              h="full"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Screenshot;