import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Link,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link as RouterLink } from 'react-router-dom';

const SecurityInfo = ({ scanData, cardBgColor }) => {
  const renderSSLDetails = (certDetails) => {
    if (!certDetails) {
      return <Text className="text-gray-700 dark:text-gray-300">No SSL/TLS details available</Text>;
    }

    let parsedCertDetails;
    try {
      parsedCertDetails = typeof certDetails === 'string' ? JSON.parse(certDetails) : certDetails;
    } catch (error) {
      console.error("Error parsing cert_details:", error);
      return <Text className="text-gray-700 dark:text-gray-300">Error parsing SSL/TLS details</Text>;
    }

    return (
      <VStack align="stretch" spacing={2}>
        <Text className="text-gray-900 dark:text-white">
          <strong>Issuer:</strong> {parsedCertDetails.issuer}
        </Text>
        <Text className="text-gray-900 dark:text-white">
          <strong>Subject:</strong> {parsedCertDetails.subject}
        </Text>
        <Text className="text-gray-900 dark:text-white">
          <strong>Valid From:</strong> {parsedCertDetails.valid_from}
        </Text>
        <Text className="text-gray-900 dark:text-white">
          <strong>Valid To:</strong> {parsedCertDetails.valid_to}
        </Text>
        <Text className="text-gray-900 dark:text-white">
          <strong>Serial Number:</strong> {parsedCertDetails.serial_number}
        </Text>
      </VStack>
    );
  };

  const renderJARMInfo = (jarmFingerprint) => {
    if (!jarmFingerprint) {
      return <Text className="text-gray-700 dark:text-gray-300">No JARM fingerprint available</Text>;
    }

    const jarmMatch = jarmFingerprint.match(/JARM:\s*([a-f0-9]+)/i);
    let jarmHash = jarmMatch ? jarmMatch[1] : 'N/A';

    return (
      <VStack align="stretch" spacing={2}>
        <Text className="text-gray-900 dark:text-white">
          <strong>JARM Fingerprint:</strong>{' '}
          <Link
            as={RouterLink}
            to={`/recent-scans?jarm=${jarmHash}`}
            color="blue.500"
            _hover={{ textDecoration: 'underline' }}
            aria-label={`Search for scans with JARM fingerprint ${jarmHash}`}
          >
            {jarmHash}
          </Link>
        </Text>
      </VStack>
    );
  };

  const getSafeBrowsingColor = (status) => {
    if (status && status.toUpperCase() === 'UNSAFE') return 'text-red-500';
    if (status && status.toUpperCase() === 'SAFE') return 'text-green-500';
    return 'text-gray-500';
  };

  return (
    <Box bg={cardBgColor} shadow="md" rounded="lg" p={6} display="flex" flexDirection="column">
      <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faShieldAlt} className="mr-2 text-gray-900 dark:text-white" aria-hidden="true" /> Security Information
      </Heading>
      <StatGroup>
        <Stat>
          <StatLabel className="text-gray-700 dark:text-gray-300">SafeBrowsing Status</StatLabel>
          <StatNumber className={getSafeBrowsingColor(scanData.safebrowsing)} aria-label={`SafeBrowsing Status: ${scanData.safebrowsing || 'Not Available'}`}>
            {scanData.safebrowsing || 'N/A'}
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel className="text-gray-700 dark:text-gray-300">Web Server</StatLabel>
          <StatNumber className="text-gray-900 dark:text-white">{scanData.web_server || 'N/A'}</StatNumber>
        </Stat>
      </StatGroup>
      <Box mt={4}>
        <Heading as="h3" size="md" mb={2} className="text-gray-900 dark:text-white">
          <FontAwesomeIcon icon={faLock} className="mr-2 text-gray-900 dark:text-white" aria-hidden="true" /> SSL/TLS Details
        </Heading>
        <VStack align="stretch" spacing={2} pl={6}>
          {renderSSLDetails(scanData.cert_details)}
          {renderJARMInfo(scanData.jarm_fingerprint)}
        </VStack>
      </Box>
    </Box>
  );
};

export default SecurityInfo;