import React from 'react';
import { VStack, Button, Box, useColorModeValue } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey, faCodeBranch } from '@fortawesome/free-solid-svg-icons';

const SettingsNav = ({ activePage, setActivePage }) => {
  const bgColor = useColorModeValue('white', 'gray.800');

  return (
    <Box bg={bgColor} borderRadius="md" p={4} shadow="md">
      <VStack spacing={4} align="stretch" width="100%">
        <NavButton
          icon={faUser}
          text="User Details"
          isActive={activePage === 'details'}
          onClick={() => setActivePage('details')}
        />
        <NavButton
          icon={faKey}
          text="API Key"
          isActive={activePage === 'api'}
          onClick={() => setActivePage('api')}
        />
        <NavButton
          icon={faCodeBranch}
          text="Webhooks"
          isActive={activePage === 'webhook'}
          onClick={() => setActivePage('webhook')}
        />
      </VStack>
    </Box>
  );
};

const NavButton = ({ icon, text, isActive, onClick }) => {
  const activeBg = useColorModeValue('blue.500', 'blue.600');
  const inactiveBg = 'transparent';
  const activeColor = 'white';
  const inactiveColor = useColorModeValue('gray.800', 'gray.200');
  const hoverBg = useColorModeValue('blue.100', 'blue.700');

  return (
    <Button
      leftIcon={<FontAwesomeIcon icon={icon} />}
      onClick={onClick}
      variant={isActive ? 'solid' : 'ghost'}
      colorScheme={isActive ? 'blue' : 'gray'}
      justifyContent="flex-start"
      bg={isActive ? activeBg : inactiveBg}
      color={isActive ? activeColor : inactiveColor}
      _hover={{
        bg: isActive ? activeBg : hoverBg,
        color: isActive ? activeColor : inactiveColor,
      }}
      transition="background-color 0.2s, color 0.2s"
    >
      {text}
    </Button>
  );
};

export default SettingsNav;