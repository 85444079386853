import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Box } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

const Breadcrumbs = ({ items }) => {
  return (
    <Box mb={6}>
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.400" className="text-gray-400 dark:text-gray-500" />}
        className="text-gray-700 dark:text-gray-300"
      >
        {items.map((item, index) => (
          <BreadcrumbItem key={index} isCurrentPage={index === items.length - 1}>
            <BreadcrumbLink href={item.href} className="text-gray-700 dark:text-gray-300">
              {item.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Box>
  );
};

export default Breadcrumbs;