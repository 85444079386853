// utils/supabaseClient.js
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey);

export const signInWithOtp = async (email) => {
  try {
    const { data, error } = await supabase.auth.signInWithOtp({
      email,
    });
    console.log('signInWithOtp response:', { data, error });
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error during signInWithOtp:', error);
    throw error;
  }
};

export const signInWithGoogle = async () => {
  try {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: window.location.origin
      }
    });
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error during Google sign in:', error);
    throw error;
  }
};