import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import { AuthProvider } from './utils/AuthContext';
import { ChakraProvider } from '@chakra-ui/react';
import ResultsPage from './components/ResultsPage';
import RecentScans from './components/RecentScans';
import SettingsPage from './components/SettingsPage';
import ProtectedRoute from './components/ProtectedRoute';
import NotFoundPage from './components/NotFoundPage'; // Import the NotFoundPage component
import PrivacyPolicyPage from './components/PrivacyPolicyPage'; // Import the new PrivacyPolicyPage component

function App() {
  return (
    <ChakraProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/results/:jobId" element={<ResultsPage />} />
            <Route path="/recent-scans" element={<RecentScans />} />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <SettingsPage />
                </ProtectedRoute>
              }
            />
            <Route path="/privacy" element={<PrivacyPolicyPage />} /> // Add the new route for the Privacy Policy page
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;