import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faCalendarAlt, faClock, faServer, faEnvelope, faBuilding } from '@fortawesome/free-solid-svg-icons';

const WhoisInfo = ({ whoisData }) => {
  let parsedData;
  try {
    parsedData = typeof whoisData === 'string' ? JSON.parse(whoisData) : whoisData;
  } catch (error) {
    return <Text className="text-red-500">Error parsing WHOIS data</Text>;
  }

  const formatDate = (dateString) => {
    if (Array.isArray(dateString) && dateString.length > 0) {
      dateString = dateString[0];
    }
    if (typeof dateString === 'string') {
      const date = new Date(dateString);
      return date.toLocaleString();
    }
    return 'N/A';
  };

  return (
    <VStack align="stretch" spacing={2}>
      <HStack>
        <FontAwesomeIcon icon={faGlobe} className="text-gray-900 dark:text-white" />
        <Text className="text-gray-900 dark:text-white"><strong>Domain Name:</strong> {parsedData.domain_name || 'N/A'}</Text>
      </HStack>
      <HStack>
        <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-900 dark:text-white" />
        <Text className="text-gray-900 dark:text-white"><strong>Creation Date:</strong> {formatDate(parsedData.creation_date)}</Text>
      </HStack>
      <HStack>
        <FontAwesomeIcon icon={faClock} className="text-gray-900 dark:text-white" />
        <Text className="text-gray-900 dark:text-white"><strong>Updated Date:</strong> {formatDate(parsedData.updated_date)}</Text>
      </HStack>
      <HStack>
        <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-900 dark:text-white" />
        <Text className="text-gray-900 dark:text-white"><strong>Expiry Date:</strong> {formatDate(parsedData.expiration_date)}</Text>
      </HStack>
      <HStack>
        <FontAwesomeIcon icon={faServer} className="text-gray-900 dark:text-white" />
        <Text className="text-gray-900 dark:text-white"><strong>Registrar:</strong> {parsedData.registrar || 'N/A'}</Text>
      </HStack>
      <HStack>
        <FontAwesomeIcon icon={faEnvelope} className="text-gray-900 dark:text-white" />
        <Text className="text-gray-900 dark:text-white"><strong>Emails:</strong> {parsedData.emails || 'N/A'}</Text>
      </HStack>
      <HStack>
        <FontAwesomeIcon icon={faBuilding} className="text-gray-900 dark:text-white" />
        <Text className="text-gray-900 dark:text-white"><strong>Organization:</strong> {parsedData.org || 'N/A'}</Text>
      </HStack>
      {parsedData.name_servers && (
        <Box>
          <Text className="text-gray-900 dark:text-white"><strong>Nameservers:</strong></Text>
          <VStack align="start" pl={6}>
            {Array.isArray(parsedData.name_servers)
              ? parsedData.name_servers.map((ns, index) => (
                  <Text key={index} className="text-gray-900 dark:text-white">{ns}</Text>
                ))
              : <Text className="text-gray-900 dark:text-white">{parsedData.name_servers}</Text>
            }
          </VStack>
        </Box>
      )}
    </VStack>
  );
};

const renderMXRecords = (mxRecords) => {
  if (!mxRecords || mxRecords.length === 0) {
    return <Text className="text-gray-700 dark:text-gray-300">No MX Records</Text>;
  }

  let parsedRecords;
  try {
    parsedRecords = typeof mxRecords === 'string' ? JSON.parse(mxRecords) : mxRecords;
  } catch (error) {
    console.error("Error parsing MX records:", error);
    return <Text className="text-gray-700 dark:text-gray-300">Error parsing MX records</Text>;
  }

  if (!Array.isArray(parsedRecords)) {
    return <Text className="text-gray-700 dark:text-gray-300">Invalid MX records format</Text>;
  }

  return (
    <UnorderedList spacing={2}>
      {parsedRecords.map((record, index) => (
        <ListItem key={index} className="text-gray-900 dark:text-white">{record}</ListItem>
      ))}
    </UnorderedList>
  );
};

const DomainInformation = ({ scanData, cardBgColor }) => {
  const renderWhoisInfo = () => {
    if (!scanData.whois) {
      return <Text className="text-gray-700 dark:text-gray-300">No WHOIS information available</Text>;
    }

    if (scanData.whois.error) {
      return <Text className="text-red-500">{scanData.whois.error}</Text>;
    }

    return <WhoisInfo whoisData={scanData.whois} />;
  };

  return (
    <Box bg={cardBgColor} shadow="md" rounded="lg" p={6} display="flex" flexDirection="column">
      <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faGlobe} className="mr-2 text-gray-900 dark:text-white" aria-hidden="true" /> Domain Information
      </Heading>
      <Accordion allowMultiple defaultIndex={[0]}>
        <AccordionItem borderColor="gray.700">
          <h3>
            <AccordionButton>
              <Box flex="1" textAlign="left" className="text-gray-900 dark:text-white">
                Domain Details
              </Box>
              <AccordionIcon className="text-gray-900 dark:text-white" />
            </AccordionButton>
          </h3>
          <AccordionPanel pb={4}>
            {renderWhoisInfo()}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem borderColor="gray.700">
          <h3>
            <AccordionButton>
              <Box flex="1" textAlign="left" className="text-gray-900 dark:text-white">
                MX Records
              </Box>
              <AccordionIcon className="text-gray-900 dark:text-white" />
            </AccordionButton>
          </h3>
          <AccordionPanel pb={4}>
            {renderMXRecords(scanData.mx_records)}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default DomainInformation;