import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-100 dark:bg-gray-900 text-gray-600 dark:text-gray-300">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img className="h-10 w-auto hidden dark:block" src="/logo-dark.svg" alt="AIURL Scanner" />
            <img className="h-10 w-auto dark:hidden" src="/logo-light.svg" alt="AIURL Scanner" />
            <p className="text-gray-500 dark:text-gray-400 text-base">
              Scan. Protect. Surf Safe.
            </p>
            <div className="flex space-x-6">
              <a href="#" className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
                <span className="sr-only">Facebook</span>
                <FontAwesomeIcon icon={faFacebookF} className="h-6 w-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
                <span className="sr-only">Twitter</span>
                <FontAwesomeIcon icon={faTwitter} className="h-6 w-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
                <span className="sr-only">LinkedIn</span>
                <FontAwesomeIcon icon={faLinkedinIn} className="h-6 w-6" />
              </a>
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 dark:text-gray-200 tracking-wider uppercase">Solutions</h3>
                <ul className="mt-4 space-y-4">
                  <li><a href="#" className="text-base hover:text-gray-900 dark:hover:text-white">URL Scanning</a></li>
                  <li><a href="#" className="text-base hover:text-gray-900 dark:hover:text-white">Phishing Detection</a></li>
                  <li><a href="#" className="text-base hover:text-gray-900 dark:hover:text-white">AI Analysis</a></li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 dark:text-gray-200 tracking-wider uppercase">Support</h3>
                <ul className="mt-4 space-y-4">
                  <li><a href="#" className="text-base hover:text-gray-900 dark:hover:text-white">Pricing</a></li>
                  <li><a href="#" className="text-base hover:text-gray-900 dark:hover:text-white">Documentation</a></li>
                  <li><a href="#" className="text-base hover:text-gray-900 dark:hover:text-white">API Status</a></li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 dark:text-gray-200 tracking-wider uppercase">Company</h3>
                <ul className="mt-4 space-y-4">
                  <li><a href="#" className="text-base hover:text-gray-900 dark:hover:text-white">About</a></li>
                  <li><a href="#" className="text-base hover:text-gray-900 dark:hover:text-white">Blog</a></li>
                  <li><a href="#" className="text-base hover:text-gray-900 dark:hover:text-white">Careers</a></li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 dark:text-gray-200 tracking-wider uppercase">Legal</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/privacy" className="text-base hover:text-gray-900 dark:hover:text-white">
                      Privacy
                    </Link>
                  </li>
                  <li><a href="#" className="text-base hover:text-gray-900 dark:hover:text-white">Terms</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 dark:border-gray-700 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; {currentYear} AIURL Scanner. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;