import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  useClipboard,
  useToast,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  Alert,
  AlertIcon,
  Code,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCopy } from '@fortawesome/free-solid-svg-icons';

const APIKeySection = ({ userData }) => {
  const [showApiKey, setShowApiKey] = useState(false);
  const { hasCopied, onCopy } = useClipboard(userData?.api_key || '');
  const toast = useToast();

  const handleCopyClick = () => {
    onCopy();
    toast({
      title: 'API Key copied',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  const maskedApiKey = userData?.api_key ? '*'.repeat(userData.api_key.length) : '';

  const apiExample = `
# Analyze a URL
curl -X POST \\
  https://slam.aiurl.io/user/analyze \\
  -H 'Content-Type: application/json' \\
  -d '{
    "url": "https://example.com",
    "user_api_key": "YOUR_API_KEY_HERE"
  }'

# Check analysis status
curl -H "X-User-API-Key: YOUR_API_KEY_HERE" \\
  https://slam.aiurl.io/user/status/ANALYSIS_ID_HERE
`;

  return (
    <VStack spacing={6} align="stretch">
      <Text fontSize="2xl" fontWeight="bold" className="text-gray-900 dark:text-white">
        API Key
      </Text>

      <Alert status="warning" rounded="md" className="bg-yellow-100 text-black dark:bg-yellow-900 dark:text-black">
        <AlertIcon />
        Never share your API key publicly or with unauthorized individuals. Keep it secure to prevent unauthorized access to your account.
      </Alert>

      <Box>
        <Text mb={2} className="text-gray-900 dark:text-white">
          Your API Key:
        </Text>
        <InputGroup size="md">
          <Input
            pr="4.5rem"
            type={showApiKey ? 'text' : 'password'}
            value={showApiKey ? userData?.api_key : maskedApiKey}
            readOnly
            className="text-black dark:text-white"
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={toggleApiKeyVisibility}>
              <FontAwesomeIcon icon={showApiKey ? faEyeSlash : faEye} />
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>

      <Button leftIcon={<FontAwesomeIcon icon={faCopy} />} onClick={handleCopyClick} colorScheme="blue" className="text-white">
        {hasCopied ? 'Copied!' : 'Copy API Key'}
      </Button>

      <Text fontSize="sm" className="text-gray-700 dark:text-gray-200">
        Use this API key to authenticate requests to our API. Do not share this key in publicly accessible areas such as GitHub, client-side code, or in API requests to other services.
      </Text>

      <Box>
        <Text mb={2} fontWeight="bold" className="text-gray-900 dark:text-white">
          API Usage Example:
        </Text>
        <Box borderRadius="md" borderWidth="1px" borderColor="gray.200" className="border-gray-200 dark:border-gray-700">
          <Code display="block" whiteSpace="pre-wrap" overflowWrap="break-word" p={4} fontSize="sm" className="text-gray-900 dark:text-white">
            {apiExample}
          </Code>
        </Box>
      </Box>

      <Text fontSize="sm" className="text-gray-700 dark:text-gray-200">
        Replace 'YOUR_API_KEY_HERE' with your actual API key, and 'ANALYSIS_ID_HERE' with the ID returned from the analyze endpoint. API usage counts against your monthly quota.
      </Text>
    </VStack>
  );
};

export default APIKeySection;