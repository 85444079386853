import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Center,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const SuspiciousIndicators = ({ scanData, cardBgColor }) => {
  const renderSuspiciousIndicators = () => {
    if (scanData.suspicious_indicators && scanData.suspicious_indicators.length > 0) {
      const uniqueIndicators = [...new Set(scanData.suspicious_indicators)];
      return (
        <VStack align="stretch" spacing={2} role="list">
          {uniqueIndicators.map((indicator, index) => (
            <HStack key={index} spacing={3} align="center" role="listitem">
              <Center w="20px" h="20px" className="text-red-500" aria-hidden="true">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Center>
              <Text className="text-gray-700 dark:text-gray-200">{indicator}</Text>
            </HStack>
          ))}
        </VStack>
      );
    } else {
      return <Text className="text-gray-700 dark:text-gray-300">No suspicious indicators found.</Text>;
    }
  };

  return (
    <Box bg={cardBgColor} shadow="md" rounded="lg" p={6} display="flex" flexDirection="column">
      <Heading as="h2" size="lg" mb={4} className="text-gray-900 dark:text-white">
        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 text-gray-900 dark:text-white" aria-hidden="true" /> Suspicious Indicators
      </Heading>
      {renderSuspiciousIndicators()}
    </Box>
  );
};

export default SuspiciousIndicators;