import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../utils/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import { Switch, FormControl, FormLabel } from '@chakra-ui/react';
import SearchComponent from './SearchComponent';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet-async';

const ITEMS_PER_PAGE = 10;

const RecentScans = () => {
  const [scans, setScans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [isPrivate, setIsPrivate] = useState(false);
  const [myScans, setMyScans] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const observer = useRef();
  const { session } = useContext(AuthContext);

  // Reset myScans if user logs out
  useEffect(() => {
    if (!session) {
      setMyScans(false);
    }
  }, [session]);

  const lastScanElementRef = useCallback(node => {
    if (loadingMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreScans();
      }
    });
    if (node) observer.current.observe(node);
  }, [loadingMore, hasMore]);

  const fetchScans = useCallback(async (pageIndex = 0) => {
    setLoadingMore(true);
    const startIndex = pageIndex * ITEMS_PER_PAGE;
    let query = supabase
      .from('ripper_details')
      .select('*', { count: 'exact' })
      .order('timestamp', { ascending: false });

    if (searchTerm) {
      query = query.ilike('original_url', `%${searchTerm}%`);
    }

    // Check if the user is logged in
    if (session) {
      // If the user is logged in, show private scans only for the current user
      query = query.or(`is_private.eq.${false},and(is_private.eq.${true},id.eq.${session.user.id})`);
    } else {
      // If the user is not logged in, exclude all private scans
      query = query.eq('is_private', false);
    }

    if (myScans) {
      if (session) {
        query = query.eq('id', session.user.id);
      } else {
        // If myScans is true but no session, show no results
        query = query.eq('id', 'no_session');
      }
    }

    const params = new URLSearchParams(location.search);
    const jarmHash = params.get('jarm');
    if (jarmHash) {
      query = query.ilike('jarm_fingerprint', `%${jarmHash}%`);
    }

    const { count, error: countError } = await query;

    if (countError) {
      console.error('Error fetching count:', countError);
      setLoading(false);
      setLoadingMore(false);
      return;
    }

    const { data, error } = await query
      .range(startIndex, startIndex + ITEMS_PER_PAGE - 1);

    if (error) {
      console.error('Error fetching scans:', error);
      setLoading(false);
      setLoadingMore(false);
      return;
    }

    setScans(prevScans => {
      const newScans = pageIndex === 0 ? data : [...prevScans, ...data];
      return newScans.filter((scan, index, self) =>
        index === self.findIndex((t) => t.id === scan.id)
      );
    });
    setTotalCount(count);
    setHasMore(data.length === ITEMS_PER_PAGE && startIndex + data.length < count);
    setPage(pageIndex);
    setLoading(false);
    setLoadingMore(false);
  }, [searchTerm, isPrivate, myScans, session, location.search]);

  useEffect(() => {
    setLoading(true);
    setPage(0);
    fetchScans(0);
  }, [fetchScans, searchTerm, isPrivate, myScans]);

  const loadMoreScans = useCallback(() => {
    if (!loadingMore && hasMore) {
      fetchScans(page + 1);
    }
  }, [loadingMore, hasMore, fetchScans, page]);

  const handleSearch = useCallback((term) => {
    setSearchTerm(term);
  }, []);

  const handlePrivateToggle = useCallback((e) => {
    setIsPrivate(e.target.checked);
  }, []);

  const handleMyScansToggle = useCallback((e) => {
    if (!session) {
      // Optionally, show a message that user needs to log in
      alert("Please log in to view your scans");
      return;
    }
    setMyScans(e.target.checked);
  }, [session]);

  const getRiskColor = useCallback((score) => {
    if (score === null || score === undefined || score === '') return 'bg-gray-100 text-gray-800';
    if (score < 30) return 'bg-green-100 text-green-800';
    if (score < 70) return 'bg-yellow-100 text-yellow-800';
    return 'bg-red-100 text-red-800';
  }, []);

  const getRiskText = useCallback((score) => {
    if (score === null || score === undefined || score === '') return 'NO SCORE';
    return `RISK: ${score}`;
  }, []);

  const truncateUrl = useCallback((url, maxLength = 50) => {
    if (url.length <= maxLength) return url;
    const mid = Math.floor(maxLength / 2);
    return `${url.slice(0, mid)}...${url.slice(-mid)}`;
  }, []);

  const getResizedImageUrl = useCallback((originalUrl, width = 600, height = 400) => {
    if (!originalUrl) return null;
    
    // Transform the URL to use the render/image path
    const baseUrl = 'https://hxrbmdbkeevaoorhpzhd.supabase.co/storage/v1/';
    const renderPath = 'render/image/public/';
    const transformedUrl = originalUrl.replace(baseUrl + 'object/public/', baseUrl + renderPath);
    
    // Append resize parameters
    const separator = transformedUrl.includes('?') ? '&' : '?';
    const resizeParams = `${separator}width=${width}&height=${height}&quality=80`;
    
    return `${transformedUrl}${resizeParams}`;
  }, []);

  const handleParentClick = useCallback((e, parentJobId) => {
    e.stopPropagation();
    navigate(`/results/${parentJobId}`);
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>AI URL Scanner | Recent Scans</title>
        <meta name="description" content="View recent scans performed by our AI-powered URL scanner." />
        <meta name="keywords" content="URL scanner, recent scans, website security, threat detection" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col">
        <Header />
        <main className="container mx-auto py-10 px-4 sm:px-6 lg:px-8 flex-grow">
          <h1 className="text-3xl font-bold text-center text-gray-900 dark:text-white mb-2">
            Recent Scans
          </h1>
          <p className="text-center text-gray-600 dark:text-gray-400 mb-6">
            Total Scans: {totalCount}
          </p>
          <SearchComponent onSearch={handleSearch} />
          <div className="flex items-center mt-4 space-x-4">
            <FormControl display="flex" alignItems="center" w="auto">
              <FormLabel htmlFor="private" mb="0" mr={2} className="text-gray-700 dark:text-gray-300">
                Private
              </FormLabel>
              <Switch
                id="private"
                isChecked={isPrivate}
                onChange={(e) => setIsPrivate(e.target.checked)}
                colorScheme="blue"
                aria-label="Toggle private scans"
              />
            </FormControl>
            <FormControl display="flex" alignItems="center" w="auto">
              <FormLabel htmlFor="myScans" mb="0" mr={2} className="text-gray-700 dark:text-gray-300">
                My Scans
              </FormLabel>
              <Switch
                id="myScans"
                isChecked={myScans}
                onChange={handleMyScansToggle}
                colorScheme="blue"
                isDisabled={!session}
                aria-label="Toggle my scans"
              />
            </FormControl>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-6" role="list">
            {scans.map((scan, index) => (
              <div 
                key={scan.id}
                className="bg-white dark:bg-gray-800 overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300 rounded-lg cursor-pointer"
                onClick={() => navigate(`/results/${scan.job_id}`)}
                ref={index === scans.length - 1 ? lastScanElementRef : null}
                role="listitem"
              >
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white truncate">
                    {truncateUrl(scan.original_url)}
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2 text-sm" aria-hidden="true" />
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                    Scanned on: {new Date(scan.timestamp).toLocaleString()}
                  </p>
                  <div className="flex flex-wrap gap-2 mt-2" role="group" aria-label="Scan details">
                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getRiskColor(scan.final_score)}`}>
                      {getRiskText(scan.final_score)}
                    </span>
                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${scan.alive === 'alive' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                      {scan.alive === 'alive' ? 'WAS_LIVE' : 'WAS_DEAD'}
                    </span>
                    <span className={`px-2 py-1 text-xs font-semibold rounded-full ${scan.type === 'child' ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'}`}>
                      {scan.type === 'child' ? 'DISCOVERED' : 'PRIMARY'}
                    </span>
                    {scan.type === 'child' && scan.parent_job_id && (
                      <button
                        onClick={(e) => handleParentClick(e, scan.parent_job_id)}
                        className="text-blue-600 dark:text-blue-400 hover:underline text-sm uppercase"
                        aria-label="View parent scan"
                      >
                        <FontAwesomeIcon icon={faLink} aria-hidden="true" /> PARENT
                      </button>
                    )}
                  </div>
                </div>
                {scan.screenshot_url ? (
                  <img
                    src={getResizedImageUrl(scan.screenshot_url)}
                    alt={`Screenshot of ${truncateUrl(scan.original_url)}`}
                    className="w-full h-32 object-cover"
                    loading="lazy"
                  />
                ) : (
                  <div className="w-full h-32 bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                    <span className="text-gray-500 dark:text-gray-400 text-sm">No preview available</span>
                  </div>
                )}
              </div>
            ))}
          </div>
          {(loading || loadingMore) && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-6" aria-live="polite" aria-busy="true">
              {[...Array(3)].map((_, i) => (
                <div key={i} className="bg-gray-200 dark:bg-gray-700 h-64 rounded-lg animate-pulse" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ))}
            </div>
          )}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default RecentScans;