import React, { useState, useEffect } from 'react';
import { Box, VStack, FormControl, FormLabel, Input, Button, Text, useToast, HStack, IconButton } from '@chakra-ui/react';
import { supabase } from '../utils/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';

const WebhookSection = ({ userData }) => {
  const [webhooks, setWebhooks] = useState([]);
  const [newWebhookUrl, setNewWebhookUrl] = useState('');
  const [newWebhookHeaders, setNewWebhookHeaders] = useState([{ key: '', value: '' }]);
  const toast = useToast();

  const sanitizeInput = (input) => {
    const sanitized = DOMPurify.sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
    const doc = new DOMParser().parseFromString(sanitized, 'text/html');
    return doc.body.textContent || "";
  };

  useEffect(() => {
    fetchWebhooks();
  }, [userData]);

  const fetchWebhooks = async () => {
    if (userData) {
      const { data, error } = await supabase
        .from('user_webhooks')
        .select('*')
        .eq('user_id', userData.id);

      if (error) {
        console.error('Error fetching webhooks:', error);
      } else {
        setWebhooks(data);
      }
    }
  };

  const handleAddHeader = () => {
    if (newWebhookHeaders.length < 5) {
      setNewWebhookHeaders([...newWebhookHeaders, { key: '', value: '' }]);
    }
  };

  const handleRemoveHeader = (index) => {
    const updatedHeaders = newWebhookHeaders.filter((_, i) => i !== index);
    setNewWebhookHeaders(updatedHeaders);
  };

  const handleHeaderChange = (index, field, value) => {
    const updatedHeaders = newWebhookHeaders.map((header, i) => 
      i === index ? { ...header, [field]: value } : header
    );
    setNewWebhookHeaders(updatedHeaders);
  };

  const handleAddWebhook = async (e) => {
    e.preventDefault();
    const sanitizedUrl = sanitizeInput(newWebhookUrl);
    if (!sanitizedUrl) {
      toast({
        title: 'Error',
        description: 'Please provide a valid webhook URL.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const sanitizedHeaders = newWebhookHeaders
      .filter(h => h.key && h.value)
      .map(h => ({
        key: sanitizeInput(h.key),
        value: sanitizeInput(h.value)
      }));

    const { data, error } = await supabase
      .from('user_webhooks')
      .insert([
        { user_id: userData.id, url: sanitizedUrl, headers: JSON.stringify(sanitizedHeaders) }
      ]);

    if (error) {
      console.error('Error adding webhook:', error);
      toast({
        title: 'Error adding webhook',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } else {
      setNewWebhookUrl('');
      setNewWebhookHeaders([{ key: '', value: '' }]);
      fetchWebhooks();
      toast({
        title: 'Webhook added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteWebhook = async (id) => {
    const { error } = await supabase
      .from('user_webhooks')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting webhook:', error);
      toast({
        title: 'Error deleting webhook',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } else {
      fetchWebhooks();
      toast({
        title: 'Webhook deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Text fontSize="2xl" fontWeight="bold" mb={4} className="text-gray-900 dark:text-white">Webhooks</Text>
      <form onSubmit={handleAddWebhook}>
        <VStack spacing={4} align="stretch">
          <FormControl>
            <FormLabel className="text-gray-700 dark:text-gray-300">New Webhook URL</FormLabel>
            <Input
              value={newWebhookUrl}
              onChange={(e) => setNewWebhookUrl(e.target.value)}
              placeholder="https://example.com/webhook"
              className="bg-white text-black dark:bg-gray-700 dark:text-white"
            />
          </FormControl>
          {newWebhookHeaders.map((header, index) => (
            <HStack key={index}>
              <Input
                placeholder="Header Key"
                value={header.key}
                onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
                className="bg-white text-black dark:bg-gray-700 dark:text-white"
              />
              <Input
                placeholder="Header Value"
                value={header.value}
                onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
                className="bg-white text-black dark:bg-gray-700 dark:text-white"
              />
              {index > 0 && (
                <IconButton
                  icon={<FontAwesomeIcon icon={faMinus} />}
                  onClick={() => handleRemoveHeader(index)}
                  aria-label="Remove header"
                  colorScheme="red"
                />
              )}
            </HStack>
          ))}
          {newWebhookHeaders.length < 5 && (
            <Button onClick={handleAddHeader} leftIcon={<FontAwesomeIcon icon={faPlus} />} colorScheme="green">
              Add Header
            </Button>
          )}
          <Button type="submit" colorScheme="blue" leftIcon={<FontAwesomeIcon icon={faPlus} />} className="text-white">
            Add Webhook
          </Button>
        </VStack>
      </form>
      <Box mt={8}>
        <Text fontSize="xl" fontWeight="bold" mb={4} className="text-gray-900 dark:text-white">Your Webhooks</Text>
        {webhooks.map((webhook) => (
          <Box key={webhook.id} p={4} borderWidth={1} borderRadius="md" mb={4} className="border-gray-200 dark:border-gray-700">
            <VStack align="stretch" spacing={2}>
              <Text className="text-gray-900 dark:text-white"><strong>URL:</strong> {webhook.url}</Text>
              <Text className="text-gray-900 dark:text-white"><strong>Headers:</strong></Text>
              {webhook.headers && JSON.parse(webhook.headers).map((header, index) => (
                <Text key={index} className="text-gray-700 dark:text-gray-300">{header.key}: {header.value}</Text>
              ))}
              {(!webhook.headers || JSON.parse(webhook.headers).length === 0) && (
                <Text className="text-gray-700 dark:text-gray-300">No headers</Text>
              )}
              <Button
                size="sm"
                colorScheme="red"
                onClick={() => handleDeleteWebhook(webhook.id)}
                leftIcon={<FontAwesomeIcon icon={faTrash} />}
                className="text-white"
              >
                Delete
              </Button>
            </VStack>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default WebhookSection;