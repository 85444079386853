import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faCalendarAlt, faClock, faInfoCircle, faCopy, faExclamationTriangle, faUser, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ScanHero = ({ scanData, copyToClipboard, user }) => {
  const getRiskAssessment = (riskScore) => {
    if (riskScore === null || riskScore === undefined) {
      return {
        title: 'Unknown',
        color: 'text-blue-700 dark:text-blue-300',
        bgColor: 'bg-blue-50 dark:bg-blue-900/20',
        icon: faInfoCircle,
        scoreColor: 'text-blue-600 dark:text-blue-400'
      };
    } else if (riskScore > 80) {
      return {
        title: 'High Risk Detected',
        color: 'text-red-700 dark:text-red-300',
        bgColor: 'bg-red-50 dark:bg-red-900/20',
        icon: faExclamationTriangle,
        scoreColor: 'text-red-600 dark:text-red-400'
      };
    } else if (riskScore >= 40) {
      return {
        title: 'Moderate Risk Detected',
        color: 'text-yellow-700 dark:text-yellow-300',
        bgColor: 'bg-yellow-50 dark:bg-yellow-900/20',
        icon: faExclamationTriangle,
        scoreColor: 'text-yellow-600 dark:text-yellow-400'
      };
    } else {
      return {
        title: 'Low Risk Detected',
        color: 'text-green-700 dark:text-green-300',
        bgColor: 'bg-green-50 dark:bg-green-900/20',
        icon: faInfoCircle,
        scoreColor: 'text-green-600 dark:text-green-400'
      };
    }
  };

  const riskAssessment = getRiskAssessment(scanData.final_score);

  const getResizedImageUrl = useCallback((originalUrl, width = 384, height = 288) => {
    if (!originalUrl) return null;
    
    // Transform the URL to use the render/image path
    const baseUrl = 'https://hxrbmdbkeevaoorhpzhd.supabase.co/storage/v1/';
    const renderPath = 'render/image/public/';
    const transformedUrl = originalUrl.replace(baseUrl + 'object/public/', baseUrl + renderPath);
    
    // Append resize parameters
    const separator = transformedUrl.includes('?') ? '&' : '?';
    const resizeParams = `${separator}width=${width}&height=${height}&quality=80`;
    
    return `${transformedUrl}${resizeParams}`;
  }, []);

  return (
    <div className={`rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 mb-6 overflow-hidden ${riskAssessment.bgColor}`}>
      <div className="p-6 shadow-md border-b border-gray-200 dark:border-gray-700">
        <div className="flex flex-col md:flex-row">
          <div className="flex-grow">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Scan Results</h2>
            </div>
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faGlobe} className="text-gray-500 dark:text-gray-400 mr-2" aria-hidden="true" />
              <span className="text-gray-700 dark:text-gray-300 break-all">
                {scanData.original_url}
              </span>
              <button
                onClick={() => copyToClipboard(scanData.original_url)}
                className="ml-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                aria-label="Copy URL to clipboard"
              >
                <FontAwesomeIcon icon={faCopy} aria-hidden="true" />
              </button>
            </div>
            
            {/* Avatar, user name, and scan details */}
            <div className="flex items-center mt-4">
              {user && user.avatar_url ? (
                <img 
                  src={user.avatar_url} 
                  alt={`${user.name || 'User'}'s avatar`}
                  className="w-8 h-8 rounded-full mr-2"
                />
              ) : (
                <div className="w-8 h-8 rounded-full mr-2 bg-gray-300 dark:bg-gray-600 flex items-center justify-center" aria-hidden="true">
                  <FontAwesomeIcon icon={faUser} className="text-gray-600 dark:text-gray-300" />
                </div>
              )}
              <div className="flex flex-col">
                <span className="text-sm font-bold text-gray-900 dark:text-white">
                  Scanned by: {user?.name || 'Anonymous User'}
                </span>
                <span className="text-xs text-gray-600 dark:text-gray-400 flex items-center">
                  <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" aria-hidden="true" />
                  Analyzed on: {new Date(scanData.timestamp).toLocaleString()} UTC
                  {scanData.time_to_complete && (
                    <>
                      <span className="ml-4">
                        <FontAwesomeIcon icon={faClock} className="mr-2" aria-hidden="true" />
                        Analysis time: {scanData.time_to_complete}
                      </span>
                      {/* Add the private/public indicator */}
                      <span className="ml-2 flex items-center">
                        <FontAwesomeIcon
                          icon={scanData.is_private ? faEyeSlash : faEye}
                          className={`mr-1 ${scanData.is_private ? 'text-gray-500 dark:text-gray-400' : 'text-green-500 dark:text-green-400'}`}
                          aria-hidden="true"
                        />
                        {scanData.is_private ? 'Private' : 'Public'}
                      </span>
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
          
          {/* Screenshot */}
          {scanData.screenshot_url && (
            <div className="mt-4 md:mt-0 md:ml-4 flex-shrink-0">
              <img
                src={getResizedImageUrl(scanData.screenshot_url)}
                alt="Website Screenshot"
                className="w-full md:w-48 h-36 object-cover rounded-md"
              />
            </div>
          )}
        </div>
      </div>
      
      {/* Risk score, status, and risk assessment */}
      <div className="p-6 bg-white dark:bg-gray-800 shadow-inner">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center">
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">Risk Score</h3>
            <div className={`text-3xl font-bold ${riskAssessment.scoreColor}`} aria-label={`Risk score: ${scanData.final_score === null || scanData.final_score === undefined ? 'Not Available' : scanData.final_score}`}>
              {scanData.final_score === null || scanData.final_score === undefined ? 'N/A' : scanData.final_score}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">Status</h3>
            <div className={`text-lg font-semibold ${
              scanData.alive === 'alive' ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
            }`}>
              {scanData.alive === 'alive' ? 'Site was Live' : 'Site is Down'}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">Risk Assessment</h3>
            <div className={`text-lg font-semibold ${riskAssessment.color} flex items-center`}>
              <FontAwesomeIcon icon={riskAssessment.icon} className="mr-2" aria-hidden="true" />
              {riskAssessment.title}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanHero;