import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShieldAlt, faRobot, faBolt } from '@fortawesome/free-solid-svg-icons';
import {
  Badge,
  Progress,
  Switch,
  FormControl,
  FormLabel,
  Alert,
  AlertIcon,
  Box,
  VStack,
  Heading,
  Text,
  Input,
  Button,
  Container,
  useColorModeValue,
  Flex,
  Divider,
} from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';
import FirstTimeSetupModal from './FirstTimeSetupModal';
import { AuthContext } from '../utils/AuthContext';
import { supabase } from '../utils/supabaseClient';
import { analyzeUrl } from '../services/urlAnalysisService';
import DOMPurify from 'dompurify';
import { Turnstile } from '@marsidev/react-turnstile';
import { Helmet } from 'react-helmet-async';

const LandingPage = () => {
  const { session } = useContext(AuthContext);
  const [url, setUrl] = useState('');
  const [isFirstTimeSetupModalOpen, setIsFirstTimeSetupModalOpen] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [jobStatus, setJobStatus] = useState(null);
  const [error, setError] = useState(null);
  const [numRequests, setNumRequests] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);
  const navigate = useNavigate();
  const [isTurnstileVisible, setIsTurnstileVisible] = useState(false);
  const turnstileRef = useRef(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  useEffect(() => {
    const fetchNumRequests = async () => {
      if (session) {
        const { data, error } = await supabase
          .from('users')
          .select('num_requests')
          .eq('id', session.user.id)
          .single();

        if (error) {
          console.error('Error fetching user data:', error);
        } else {
          setNumRequests(data.num_requests);
        }
      }
    };

    fetchNumRequests();
  }, [session]);

  const getStatusMessage = (jobStatus) => {
    switch (jobStatus) {
      case 'Starting':
        return "Starting up...";
      case 'Checking':
        return "Checking URL safety...";
      case 'Gathering':
        return "Gathering DNS, SSL, and IP info...";
      case 'Capturing':
        return "Capturing screenshot...";
      case 'Processing':
        return "Analyzing with AI...";
      case 'Finalizing':
        return "Finalizing and completing the analysis...";
      case 'Completed':
        return "Completed";
      default:
        return "Processing, please allow up to 1 minute...";
    }
  };

  const sanitizeUrl = (input) => {
    // Use DOMPurify to remove any HTML tags
    const sanitized = DOMPurify.sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
    
    // Basic URL validation
    const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/i;
    if (urlPattern.test(sanitized)) {
      return sanitized;
    } else {
      return ''; // Return empty string for invalid URLs
    }
  };

  const handleUrlChange = (e) => {
    // Allow typing by setting the raw input value
    setUrl(e.target.value);
  };

  const handleScan = async () => {
    if (!isTurnstileVisible) {
      setIsTurnstileVisible(true);
      return;
    }

    try {
      setError(null);
      setIsLoading(true);

      const sanitizedUrl = sanitizeUrl(url);
      if (!sanitizedUrl) {
        setError('Please enter a valid URL.');
        setIsLoading(false);
        return;
      }

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('num_requests')
        .eq('id', session.user.id)
        .single();

      if (userError) {
        console.error('Error fetching user data:', userError);
        setError('Error fetching user data. Please try again.');
        setIsLoading(false);
        return;
      }

      if (userData.num_requests <= 0) {
        setError('You have no scans left. Please wait for the next reset.');
        setIsLoading(false);
        return;
      }

      const jobId = await analyzeUrl(sanitizedUrl, session.user.id, isPrivate);
      setJobId(jobId);

      const newRequestCount = userData.num_requests - 1;

      const { error } = await supabase
        .from('users')
        .update({ num_requests: newRequestCount })
        .eq('id', session.user.id);

      if (error) {
        console.error('Error updating request count:', error);
        setError('Error updating request count. Please try again.');
      } else {
        setNumRequests(newRequestCount);
      }

      // Navigate to ResultsPage immediately after starting the scan
      navigate(`/results/${jobId}`);
    } catch (error) {
      console.error('Error scanning URL:', error);
      setError('Error scanning URL. Please try again.');
    } finally {
      setIsLoading(false);
      setIsTurnstileVisible(false);
      turnstileRef.current?.reset();
    }
  };

  const onTurnstileSuccess = async (token) => {
    // Proceed with the scan
    await handleScan();
  };

  const closeFirstTimeSetupModal = () => {
    setIsFirstTimeSetupModalOpen(false);
  };

  const handleInputFocus = () => {
    if (!session) {
      setOpenLoginModal(true);
    }
  };

  // Reset openLoginModal when session changes
  useEffect(() => {
    if (session) {
      setOpenLoginModal(false);
    }
  }, [session]);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col">
      <Helmet>
        <title>AI URL Scanner | Home</title>
        <meta name="description" content="Scan URLs for potential risks and threats using our AI-powered scanner." />
        <meta name="keywords" content="URL scanner, AI scanner, website security, threat detection" />
        {/* Add other meta tags as needed */}
      </Helmet>
      <Header openLoginModalExternal={openLoginModal} />
      
      <main className="flex-grow">
        <div className="bg-gray-50 dark:bg-gray-800 py-16">
          <Container maxW="container.xl">
            <VStack spacing={8} align="center" textAlign="center">
              <VStack spacing={2}>
                <Heading as="h1" size="2xl" fontWeight="bold" className="text-gray-800 dark:text-white">
                  Advanced Phishing Site Detection
                </Heading>
                {/* Add role="presentation" to decorative text */}
                <Text fontSize="xl" className="text-gray-600 dark:text-gray-300" role="presentation">
                  Comprehensive URL analysis powered by AI and heuristics
                </Text>
              </VStack>
              
              <Box w="full" maxW="3xl">
                <VStack spacing={4}>
                  {session && (
                    <Flex w="full" justifyContent="center" alignItems="center">
                      {/* Add aria-label to Badge for screen readers */}
                      <Badge
                        colorScheme={numRequests <= 5 ? 'red' : 'green'}
                        fontSize="md"
                        p={2}
                        borderRadius="md"
                        aria-label={`Scans Remaining: ${numRequests}`}
                      >
                        Scans Remaining: {numRequests}
                      </Badge>
                      <Divider orientation="vertical" height="20px" mx={4} />
                      <FormControl display="flex" alignItems="center" w="auto">
                        <FormLabel htmlFor="private" mb="0" mr={2} className="text-gray-600 dark:text-gray-300">
                          Private
                        </FormLabel>
                        <Switch
                          id="private"
                          isChecked={isPrivate}
                          onChange={(e) => setIsPrivate(e.target.checked)}
                          colorScheme="teal"
                          aria-label="Toggle private mode"
                        />
                      </FormControl>
                    </Flex>
                  )}
                  
                  <Flex w="full" flexDirection="column" alignItems="center">
                    <Flex w="full">
                      <Input
                        placeholder="Enter URL to scan"
                        value={url}
                        onChange={handleUrlChange}
                        onFocus={handleInputFocus}
                        disabled={isLoading}
                        size="lg"
                        height="50px"
                        borderRightRadius="0"
                        className="dark:text-gray-300"
                        aria-label="URL to scan"
                      />
                      <Button
                        onClick={handleScan}
                        disabled={isLoading || url.trim() === ''}
                        colorScheme="blue"
                        size="lg"
                        height="50px"
                        borderLeftRadius="0"
                        aria-label="Scan URL"
                      >
                        <FontAwesomeIcon icon={faSearch} className="mr-2" aria-hidden="true" />
                        Scan
                      </Button>
                    </Flex>
                    
                    {isTurnstileVisible && (
                      <Box mt={4}>
                        <Turnstile
                          ref={turnstileRef}
                          siteKey="0x4AAAAAAAi_WQOMDkxDBG8Q"
                          onSuccess={onTurnstileSuccess}
                          onError={() => setError('Turnstile verification failed. Please try again.')}
                          onExpire={() => setIsTurnstileVisible(false)}
                          options={{
                            theme: 'light',
                          }}
                        />
                      </Box>
                    )}
                  </Flex>

                  {isLoading && (
                    <Box w="full">
                      <Progress size="xs" isIndeterminate colorScheme="blue" aria-label="Loading progress" />
                      <Text mt={2} color="blue.500" aria-live="polite">
                        {getStatusMessage(jobStatus)}
                      </Text>
                    </Box>
                  )}
                </VStack>
              </Box>
              
              {error && (
                <Alert status="error" variant="subtle" maxW="md" borderRadius="md">
                  <AlertIcon />
                  <span role="alert">{error}</span>
                </Alert>
              )}
            </VStack>
          </Container>
        </div>

        {/* Features Section */}
        <div className="py-12 bg-white dark:bg-gray-800">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-indigo-600 dark:text-indigo-400 font-semibold tracking-wide uppercase">Why Choose aiurl.io</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                Comprehensive Phishing Detection
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 dark:text-gray-300 lg:mx-auto">
                Our cutting-edge technology combines multiple heuristic methods with AI analysis to provide accurate and reliable results.
              </p>
            </div>

            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10" role="list">
                <div className="relative" role="listitem">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <FontAwesomeIcon icon={faShieldAlt} className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-white">30+ heuristics</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">Comprehensive analysis using multiple herustic methods</dd>
                </div>

                <div className="relative" role="listitem">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <FontAwesomeIcon icon={faRobot} className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-white">AI-Powered Analysis</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">Advanced machine learning algorithms for accurate results</dd>
                </div>

                <div className="relative" role="listitem">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <FontAwesomeIcon icon={faBolt} className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-white">Instant Results</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">Get immediate feedback on the safety of any URL</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </main>

      <Footer />
      <FirstTimeSetupModal
        isOpen={isFirstTimeSetupModalOpen}
        onClose={closeFirstTimeSetupModal}
      />
    </div>
  );
};

export default LandingPage;