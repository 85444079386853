import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // Import Helmet from react-helmet-async
import { Box, Container, VStack, Heading, Text, Button, Image } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';

const NotFoundPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col">
      <Helmet>
        <title>AI URL Scanner | 404 Not Found</title>
        <meta name="description" content="The requested page could not be found." />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Header />

      <main className="flex-grow">
        <div className="bg-gray-50 dark:bg-gray-800 py-16">
          <Container maxW="container.xl">
            <VStack spacing={8} align="center" textAlign="center">
              <Image
                src="https://hxrbmdbkeevaoorhpzhd.supabase.co/storage/v1/object/public/assets/not-found.jpeg?t=2024-09-18T11%3A52%3A57.240Z"
                alt="Not Found"
                borderRadius="lg" // Add rounded edges
              />
              <VStack spacing={2}>
                <Heading as="h1" size="2xl" fontWeight="bold" className="text-gray-800 dark:text-white">
                  404 Not Found
                </Heading>
                <Text fontSize="xl" className="text-gray-600 dark:text-gray-300">
                  The page you're looking for doesn't exist.
                </Text>
              </VStack>

              <Link to="/">
                <Button colorScheme="blue" size="lg" height="50px">
                  Go back to the homepage
                </Button>
              </Link>
            </VStack>
          </Container>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default NotFoundPage;