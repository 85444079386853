import React, { createContext, useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { v4 as uuidv4 } from 'uuid';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error fetching session:', error);
      } else {
        console.log('Fetched session:', data.session);
        setSession(data.session);
      }
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      (event, session) => {
        console.log('Auth state changed:', { event, session });
        setSession(session);

        if (event === 'SIGNED_OUT') {
          setSession(null);
        }

        if (session) {
          // Check if the user exists in the users table
          const checkUser = async () => {
            const { data, error: selectError } = await supabase
              .from('users')
              .select('*')
              .eq('id', session.user.id)
              .single();

            if (selectError && selectError.code !== 'PGRST116') {
              console.error('Error fetching user:', selectError);
            }

            // If the user doesn't exist, insert their data into the users table
            if (!data) {
              const { error: insertError } = await supabase
                .from('users')
                .insert([
                  {
                    id: session.user.id,
                    name: session.user.user_metadata.full_name || null,
                    avatar_url: session.user.user_metadata.avatar_url || null,
                    api_key: uuidv4(), // Generate a unique API key
                    num_requests: 50, // Set the initial number of requests
                    last_reset: new Date().toISOString(), // Set the initial reset date
                  },
                ]);

              if (insertError) {
                console.error('Error inserting user:', insertError);
              }
            } else {
              // Check if 30 days have passed since the last reset
              const lastReset = new Date(data.last_reset);
              const now = new Date();
              const daysSinceLastReset = Math.floor((now - lastReset) / (1000 * 60 * 60 * 24));

              if (daysSinceLastReset >= 30) {
                const { error: updateError } = await supabase
                  .from('users')
                  .update({ num_requests: 50, last_reset: now.toISOString() })
                  .eq('id', session.user.id);

                if (updateError) {
                  console.error('Error resetting request count:', updateError);
                }
              }
            }
          };

          checkUser();
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const value = {
    session,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};