import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  VStack,
  Heading,
  Text,
  SimpleGrid,
} from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';

const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col">
      <Helmet>
        <title>AI URL Scanner | Privacy Policy</title>
        <meta name="description" content="Privacy Policy for AI URL Scanner" />
      </Helmet>
      <Header />
      
      <main className="flex-grow">
        <div className="bg-gray-50 dark:bg-gray-800 py-16">
          <Container maxW="container.xl">
            <VStack spacing={8} align="stretch">
              <Heading as="h1" size="2xl" textAlign="center" className="text-gray-800 dark:text-white">
                Privacy Policy
              </Heading>
              <Text fontSize="lg" textAlign="center" className="text-gray-600 dark:text-gray-300">
                At aiurl.io, we are committed to protecting your privacy and ensuring the security of your personal information.
              </Text>

              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                <PolicyCard
                  title="Information We Collect"
                  content="We collect information you provide directly to us, such as when you create an account, use our services, or contact us for support. This may include your name, email address, and the URLs you submit for analysis."
                />
                <PolicyCard
                  title="How We Use Your Information"
                  content="We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to detect and prevent fraud or abuse of our platform."
                />
                <PolicyCard
                  title="Data Security"
                  content="We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage."
                />
                <PolicyCard
                  title="Data Retention"
                  content="We retain your personal information for as long as necessary to provide you with our services and as required by applicable laws. You can request deletion of your account and associated data at any time."
                />
                <PolicyCard
                  title="Third-Party Services"
                  content="We may use third-party services to help us operate our business and the aiurl.io website. These services may have access to your personal information only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose."
                />
                <PolicyCard
                  title="Your Rights"
                  content="You have the right to access, correct, or delete your personal information. You can also object to or restrict certain processing of your data. To exercise these rights, please contact us using the information provided below."
                />
              </SimpleGrid>

              <div>
                <Heading as="h2" size="xl" mb={4} className="text-gray-800 dark:text-white">
                  Changes to This Policy
                </Heading>
                <Text className="text-gray-600 dark:text-gray-300">
                  We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.
                </Text>
              </div>

              <div>
                <Heading as="h2" size="xl" mb={4} className="text-gray-800 dark:text-white">
                  Contact Us
                </Heading>
                <Text className="text-gray-600 dark:text-gray-300">
                  If you have any questions about this Privacy Policy, please contact us at privacy@aiurl.io.
                </Text>
              </div>
            </VStack>
          </Container>
        </div>
      </main>

      <Footer />
    </div>
  );
};

const PolicyCard = ({ title, content }) => (
  <div className="bg-white dark:bg-gray-800 overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300 rounded-lg p-6">
    <Heading as="h3" size="md" mb={2} className="text-gray-800 dark:text-white">
      {title}
    </Heading>
    <Text className="text-gray-600 dark:text-gray-300">{content}</Text>
  </div>
);

export default PrivacyPolicyPage;